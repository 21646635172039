.image-crop {
    .modal-header {
        padding: 1rem !important;
    }
    .save-btn {
        margin: 1.5rem;
        width: 96%;
        cursor: pointer;
        text-transform: uppercase;
        text-align: center;
        border-radius: 30px;
        background-color: var(--primary);;
        color: var(--white);
        font-size: 18px;
        font-weight: 500;
        border-color: transparent;
        padding: 1.188rem 12.25rem;
        &:hover {
          background-color: var(--primary);
          box-shadow: inset 0 0 0.5em rgba(10, 10, 10, 0.1);
        }
    }
    .cropper-crop-box, .cropper-view-box {
        border-radius: 50%;
    }
    .cropper-view-box {
        box-shadow: 0 0 0 1px #39f;
        outline: 0;
    }
    .cropper-face {
      background-color:inherit !important;
    }
    
    .cropper-dashed, .cropper-line {
      display:none !important;
    }
    .cropper-view-box {
      outline:inherit !important;
    }
    
    .cropper-point.point-se {
      top: calc(85% + 1px);
      right: 14%;
    }
    .cropper-point.point-sw {
      top: calc(85% + 1px);
      left: 14%;
    }
    .cropper-point.point-nw {
      top: calc(15% - 5px);
      left: 14%;
    }
    .cropper-point.point-ne {
      top: calc(15% - 5px);
      right: 14%;
    }
}
