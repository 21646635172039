html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}
#root {
  height: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /* Set the 'light' theme */
  --primary: #41b9e6;
  --gray: #53575e;
  --white: #fff;
  --blue: #3b62ac;
  --orange: #f05c25;
  --secondary: #4461a7;
  --light-gray: #d6d6d6;
  --light-disabled-color: #6c757d;
  --light-body-color: #eeeeee;
  --text-gray: #9e9e9e;
  --text-black: #060709;
  --border-gray: #d9d9d9;
  --border-light-gray: #e8e8e8;
  --light-bg-color: #f1fafe;
  --muted-primary: #f3f9f7;
  --primary-green: #259e45;

  /* Set the dark theme */
  --dark-primary-color: #000000;
  --dark-disabled-color: #4a4a4b;
}
.com-border-r {
  border-radius: 20px !important;
}
.bold {
  font-weight: bold !important;
}
.no-border {
  border: none !important;
}
.cursorHover {
  box-shadow: none !important;
}
div,
button {
  :focus {
    outline: none;
  }
}
.textRightAlign {
  text-align: right !important;
}
.mr6 {
  margin-right: 6px;
}
.floatR {
  float: right;
}
.clr {
  clear: both;
}
.mt5 {
  margin-top: 5px;
}
@mixin directionSwitch($ltr-property, $rtl-property, $value) {
  [dir="ltr"] & {
    #{$ltr-property}: $value;
  }

  [dir="rtl"] & {
    #{$rtl-property}: $value;
  }
}

@mixin valueSwitch($property, $ltr-value, $rtl-value) {
  [dir="ltr"] & {
    #{$property}: $ltr-value;
  }

  [dir="rtl"] & {
    #{$property}: $rtl-value;
  }
}

.app-store-wrapper {
  .modal {
    z-index: 40000;
  }

  .modal-card-head {
    border: none;
  }

  .modal-header .card-title {
    font-size: 22px !important;
    text-transform: none !important;
  }

  .mod-content {
    padding: 0 1.25rem 1.25rem 1.25rem !important;
    text-align: center;
    margin-top: -25px !important;
    z-index: 1;
  }

  .skip-btn {
    background-color: white;
    color: var(--primary);
    font-size: 14px;
    font-weight: 500;
    border-color: transparent;
    cursor: pointer;
    text-align: left;
    margin-top: 12px;

    @media (max-width: 425px) {
      font-size: 12px;
      margin-top: 8px;
    }
  }

  .app-store {
    display: block;
    height: 40px;
    width: 155px;
    margin: auto;
    border-radius: 6px;
  }

  .android {
    background: url("./assets/logo/android.png") no-repeat center;
    background-size: cover;
  }

  .iOS {
    background: url("./assets/logo/iOS.png") no-repeat center;
    background-size: cover;
  }

  .download-app-text {
    display: none;
  }

  .popup-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .popup__buttons {
      margin-left: 16px;
    }

    .popup__phone img {
      width: 200px;
    }

    @media (max-width: 425px) {
      .popup__buttons {
        margin: 0;
        position: absolute;
        padding: 20px 10px 10px 10px;
        background: white;
        border-radius: 20px;
        bottom: 25px;
        width: 210px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
      }

      .download-app-text {
        display: block;
        margin-bottom: 10px;
        font-size: small;
      }

      .popup__phone img {
        width: 250px;
      }
    }
  }

  @media (max-width: 425px) {
    .display-text {
      display: none;
    }
  }
}
