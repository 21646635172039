@import "../../../../index.scss";

.expires-in {
    font-size: 16px;
    color: var(--text-gray);
    @include directionSwitch(margin-right, margin-left, 20px);
    @media (max-width: 320px) {
        font-size: 14px;
    }
}

.timer {
    font-size: 16px;
    @include directionSwitch(margin-left, margin-right, 10px);
    color: var(--gray);
    display: flex;
    align-self: flex-end;
    @media (max-width: 425px) {
        font-size: 14px;
    }
}

.general-calender {
    margin-bottom: 3px;
    @media (max-width: 425px) {
        margin-bottom: 0;
    }
}

.timer-icon {
    display: flex;
    align-self: center;
    margin-top: 5px;
}

.timer-wrapper {
    display: flex;
    margin-top: 25px;

    @media (max-width: 768px) {
        margin-top: 15px;
        align-items: flex-end;
    }
}

.align {
    display: flex;
    align-items: flex-end;
}

.calender {
    align-items: center;
    @media (max-width: 425px) {
        align-items: baseline;
    }
}

.color {
    color: var(--gray);
}
