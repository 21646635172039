.scroll-to-top {
  position: fixed;
  z-index: 100;
  right: 3%;
  bottom: 6%;
  height: 58px;
  border-radius: 50%;
  padding: 18px;
  box-shadow: 0 12px 13px 0 rgba(0, 0, 0, 0.18);
  background-color: var(--blue);
  cursor: pointer;
  visibility: hidden;
}

.show {
  visibility: visible;
  opacity: 1;
  transition: all 300ms ease;
}

.hide {
  visibility: hidden;
}
