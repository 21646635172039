@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap");

// Set your brand colors
$green: #41b9e6;
$rtl: false;
$body-color: black;
// Update Bulma's global variables
$family-sans-serif: "Rubik", sans-serif;

$primary: $green;

// Import only what you need from Bulma

@import "~bulma/bulma";

.guest-link {
  text-align: center;
  margin-top: 10%;
  @media only screen and (max-width: 768px) {
    margin-top: 0;
  }
  .continue-as-guest {
    cursor: pointer;
    width: 100%;
    background-color: var(--light-bg-color);
    border-radius: 30px;
    min-height: 52px;
    color: var(--primary);
    font-size: 18px;
    font-weight: 500;
    border-color: transparent;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.whatsapp-widget {
  padding-right: 20px;
  @media(max-width: 500px) {

    display:block;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 10px;

  }

}

.phone-widget {
  padding-right: 16px;
  padding-left: 18px;

  @media(max-width: 500px) {

    display:block;
    padding:0;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;

  }

}

.widget-background {
  padding-top: 14px;
  padding-bottom: 12px;
  border: 1px solid #D9D9D9;
  border-radius: 0 50px 50px 0;
  position: fixed;
  bottom: 4.5%;
  z-index: 100;
  background: #fff;

  @media(max-width: 500px) {
    bottom: 0%;
    left:2.5%;
    padding-bottom: 0;
    border-radius: 50px 50px 0 0;
    
  }
}