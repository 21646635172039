@import "../../index.scss";
.field {
    position: relative;
}

.text-field {
    .has-text-primary {
        color: var(--primary);
    }

    .is-primary,
    .is-danger {

        &.input:focus,
        &.is-focused.input,
        &.input:active,
        &.is-active.input {
            box-shadow: none;
        }
    }

    .label {
        color: var(--text-gray);
        font-size: 0.75em;
        font-weight: 400;
    }

    .is-primary.input {
        border-color: var(--primary);
        box-shadow: none;
    }

    .hide {
        display: none;
    }

    .show {
        display: inline-block;
        position: absolute;
        top: -0.75em;
        @include directionSwitch(left,right,1em);
        height: fit-content;
        z-index: 1;
        padding: 0 0.5em;
        background-color: var(--white);
        animation: DisplayLabel 0.3s;
    }

    .input {
        height: 52px !important;
        border-radius: 6px !important;
        border: 2px solid var(--border-gray);

        &:disabled {
            background-color: #f2f2f2;
            color: var(--text-gray);
            border: 2px solid #e8e8e8
        }
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--text-gray) !important;
        opacity: 1;
        /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--text-gray) !important;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--text-gray) !important;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--text-gray) !important;
        opacity: 1;
        /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--text-gray) !important;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--text-gray) !important;
    }

    .control.has-icons-left .icon,
    .control.has-icons-right .icon {
        height: 52px;
    }

    .control.has-icons-right .input,
    .control.has-icons-right .select select {
        padding: 0 2.5em 0 1.3em;
    }

    .help {
        position: absolute;
        top: 100%;
        @include directionSwitch(left,right,0);
        margin-bottom: 4px;
    }
}

@keyframes DisplayLabel {
    0% {
        top: 1em;
        transform: scale(1.2);
    }

    100% {
        top: -0.75em;
        transform: scale(1);
    }
}

.icon {
    pointer-events: unset !important;
    cursor: pointer;

    :hover {
        color: #555555 !important;
    }
}

.no-pointer-events {
    pointer-events: none;
}


.phone-no {
    width: 100% !important;
    font-size: 1rem !important;
}

.react-tel-input .form-control:hover {
    border-color: #b5b5b5 !important;
}
.control.has-icons-right .icon.is-right{
    @include directionSwitch(right,left,0);
    @include valueSwitch(right,0,unset);
}

.errMsgGap {
    margin-bottom: 2.8em !important;
}
