.piechart-card {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    margin-top: 24px;
    h4{
        border-bottom: 1px solid var(--light-body-color);
        padding: 22px 24px;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .pie-card{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 87%;
        svg{
            font-size: 8px !important;
            @media(min-width: 1600px) {
                font-size: 11px !important;
            }
           
        }
        .recharts-responsive-container{
            height: auto !important;
            .recharts-wrapper{

                display: flex;
                align-items: center;
                justify-content: center;
                height: 330px !important;
                .recharts-surface{
                    height: 390px !important;
                    @media (max-width:600px){
                        width: 160px !important;
                        height: 220px !important;
                        overflow:visible;
                    }
                }
            }
           
        }
    }
}
.radial-chart-card{
    @media (max-width:1360px) and (min-width:900px){
        svg{
            width: 70% !important;
        }
        ul{
            svg{
                width: auto !important;
            }
        }
    }
    @media (max-width:700px){
        svg{
            width: 49% !important;
        }
        ul{
            svg{
                width: auto !important;
            }
        }
    }
}