.property-form {
    border-radius: 30px;
    border: solid 2px #e8e8e8;

    .form-heading {
        font-size: 22px;
        font-weight: 600;
        color: #060709;
    }

    .field:not(:last-child) {
        margin-bottom: 3rem;
    }

    .field.is-grouped {
        display: unset;
        button {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 500;
            width: 100%;
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
        @media (max-width: 425px) {
            flex-direction: column;
        }

        > div {
            width: 48%;
            margin-top: 0 !important;
            @media (max-width: 425px) {
                width: 100%;
                margin-bottom: 1.75em;
            }
        }

        @media (max-width: 425px) {
            > div:last-child {
                margin-bottom: 2.25rem;
            }
        }
    }

    .email-field {
        margin-top: -0.5rem;
    }

    .text-field .control.has-icons-right .input, .text-field .control.has-icons-right .select select {
        padding: 0 1.3em 0 1.3em;
    }

    .dropdown-trigger {
        padding: 0 1.3em 0 1.3em;
        width: 100%;
        height: 52px !important;
        border-radius: 6px !important;
        border: 2px solid var(--border-gray);
    }

    .dropdown-type {
        margin-bottom: 3rem;
        position: relative;
        @media (max-width: 425px) {
            margin-bottom: 0;
        }

        .img-btn {
            height: 100%;
            width: 100%;
            justify-content: space-between;
            font-size: inherit;
        }

        .option {
            color: var(--text-black);
        }

        .option-label {
            display: inline-block;
            position: absolute;
            top: -0.75em;
            left: 1em;
            height: -moz-fit-content;
            height: fit-content;
            z-index: 1;
            padding: 0 0.5em;
            color: var(--text-gray);
            font-size: 0.75em;
            font-weight: 400;
            background-color: var(--white);
            animation: DisplayLabel 0.3s;
        }
    }

    .text-area .is-medium.textarea {
        height: 128px;
        padding: 1em 2.5em 0 1.3em;
        font-size: 1rem;
        letter-spacing: 0.3px;
    }

    .error-msg {
        margin-top: -2.5rem;
    }

    .is-disabled.phone-input {
        background-color: white !important;
    }

    .is-disabled .flag-dropdown {
        background-color: initial !important;
        border-color: #e8e8e8 !important;
    }

    .property-ph {
        border-color: #e8e8e8 !important;
    }

    .text-area {
        margin-bottom: 2rem !important;
    }
}
