.date-range {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    min-height: 42px;
    font-size: 16px;
    padding-left: 10px;
  }

  span {
    margin-right: 10px;
  }

  .to {
    margin-left: 10px;
    @media only screen and (max-width: 1024px) {
      margin-top: 5px;
    }
  }

  .react-datepicker__header {
    background-color: white;
  }

  .react-datepicker__month-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
    padding: 10px;
    background-color: #41b9e6;
    color: white;
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    margin-top: 10px;
  }

  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover {
    background-color: #216ba5;
    color: white;
  }

  .react-datepicker__month--disabled {
    opacity: 0.65;
  }

  .react-datepicker__month-wrapper {
    user-select: none;
  }
}
