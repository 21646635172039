@import "../../../../index.scss";
.home-offers{
    .slick-track{
        display: flex;
        align-items: flex-start;
    }
}
.section.home-offers {
    padding-top: 4rem;
    font-size: 1.3em;
    padding-bottom: 0 !important;
    .deals {
        font-size: 26px;

        @media (max-width: 660px) {
            font-size: 1.1em;
            flex: 1;
        }
    }

    @media (max-width: 768px) {

        .slick-slide.slick-current,
        .slick-current+.slick-slide {
            width: 80vw;
            max-width: 468px;
           margin-left: 10px;
        }
    }

    @media (max-width: 425px) {

        .slick-slide.slick-current,
        .slick-current+.slick-slide {
            margin-bottom: 80px;
        }
    }

    @media (max-width: 560px) {
        .slick-slide.slick-current {
            width: 80vw;
            max-width: 368px;
        }
    }

    @media (max-width: 460px) {
        .slick-slide.slick-current {
            width: 76vw !important;
        }
    }

    @media (max-width: 660px) {
        padding: 0px;
    }

    .home-offers-title {
        padding-bottom: 0.5rem;
    }

    .rec-arrow {
        @media (max-width:768px) {
            display: none
        }
    }

    .offers-title {
        font-weight: 500;
        font-size: 1.5em;
    }

    .offers-viewall {
        color: var(--primary);
        font-size: 0.8em;
        font-weight: 500;
        display: contents;

        .view-all {
            cursor: pointer;
            transition: 0.5s;

            @media (max-width: 660px) {
                height: 12px;
            }
        }

        &:hover {
            cursor: pointer;

            .view-all {
                transform: translateX(3px);
            }
        }
    }

    .deal-content {
        width: 85%;
        font-weight: 500;
        padding: 2rem;
        @media (max-width:768px) {
            width: 100%;
        }

        @media (max-width: 660px) {
            padding-top: 1.5rem;
        }

        .level {
            @media (max-width:660px) {
                align-items: baseline;
            }

            @media (max-width:600px) {
                font-size: 18px;
            }
        }
    }

    .slick-list {
        padding-bottom: 70px !important;

        @media (max-width: 425px) {
            margin: 10px 20px !important;
            padding-bottom: 0 !important;
        }
    }

    .desc-green {
        color: var(--primary-green);
        margin-top: -15px;
        margin-bottom: 5px;
        font-size: medium;
        @media (max-width: 425px) {
            font-size: smaller;
        }
    }
}

.offers-card {
    background-color: #f8f8f8;
  
    &:hover {
    }

    .columns {
        background: #f8f8f8;
        border-radius: 30px;
        height: 500px;
        margin: 0 !important;
    }

    .offerBg {
        @media (max-width:768px) {
            height: 280px;
            margin-right: 0px;
        }


    }
}

.react-multi-carousel-item.offers-carousel:not(.react-multi-carousel-item--active.offers-carousel) {
    filter: opacity(0.3);
}

.slick-current {

    .offer-data,
    .offers-card {
        border-color: var(--light-bg-color) !important;
    }
}

.slick-slide {
    .box {
        padding: 0px;
        border-radius: 30px 30px 30px;

        .columns {
            margin-top: 0;
            outline: 0;
        }

        @media (max-width: 768px) {
            border-width: 0;
        }

        .offers-card {
            border: solid 2px var(--border-gray);

        }

        .offer-data {
            padding: 55px;
            background-color: var(--light-bg-color);
            border-radius: 30px;
            border: solid 2px var(--border-gray);

            @media (max-width: 768px) {
                border-radius: 0 0px 30px 30px !important;

            }

            .title {
                margin-bottom: 10px;
                font-weight: bolder;
                color: var(--text-black);
                font-size: 26px;
                @media (max-width: 768px) {
                    font-size: 22px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    height: 49px;
                }
            }

            .sub {
                font-size: 16px;
                color: var(--text-black);
            }

            .text {
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 10px;
                height: 45px;
            }

            .validity {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 25px;
            }

            .value {
                font-size: 1.4em;
                margin: 15px 0px;
                color: black;
                font-weight: 500;
                margin-top: 0px;
                color: black;
                font-weight: 500;
                margin-bottom: 10px;
            }

            .about {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: var(--gray);
                margin-bottom: 50px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .booking-grp {
                margin-bottom: 30px;

                @media (max-width: 1649px) {
                    display: flex;
                    margin-top: -1.5rem !important;

                    button {
                        font-size: 15px;
                        margin: 0;
                    }
                }

                @media (max-width: 1469px) {
                    display: initial;
                    margin-top: inherit;

                    button {
                        font-size: initial;
                        margin: initial;
                    }
                }

                @media (max-width: 405px) {
                    display: flex;
                    width: 100%;

                    button {
                        width: 100%;
                    }

                    .is-primary {
                        margin: 0 !important;
                        
                    }
                }
                @media (max-width: 320px) {
                    display: block;
                    text-align: center;
                    a:last-child{
                        margin-top: 10px !important;
                    }
                }
            }
        }

        .column {
            padding: 50px;
            background-size: cover;
            border-radius: 30px;
            padding: 40px 79px;


            @media (max-width:1300px) {
                padding: 65px;
            }

            @media (max-width:990px) {
                padding: 35px;

            }

            @media (max-width:768px) {
                padding: 20px;

            }

            @media (max-width:660px) {
            }

        }

        .offerBg {
            @include valueSwitch(border-radius, 30px 0px 0px 30px, 0px 30px 30px 0px);
            border-radius: 30px 0px 0px 30px;
            background-repeat: no-repeat;
            background-size: cover;

            @media (max-width:768px) {
                background-position: center;
                border-radius: 0px
            }
        }

        .column:first-of-type {
            @media (max-width:768px) {
                margin-right: 0px;
                border-radius: 27px 35px 0px 0px;
            }
        }
    }
}

.home-offers {


    .slick-slide {
        opacity: 0.6;
    }

    .slick-slide.slick-current {

        opacity: 1;
    }

    .rec-arrow {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: white;
        box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.14);
        font-size: 1.0em;
        color: var(--primary);
        box-sizing: border-box;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        font-size: 1.6em;
        color: #333;
        border-radius: 50%;
        border: none;
        padding: 0;
        width: 50px;
        height: 50px;
        min-width: 50px;
        line-height: 50px;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        cursor: pointer;
        outline: none;

        &:hover, &:active, &:focus {
            background-color: white !important;
        }
        
    }

    .rec-pagination {
        display: none;
    }
}

.remove {
    transition: all 0ms ease 0s !important;
}

.column.offer-data {
    border-radius: 30px;

    @media (max-width:550px) {

        margin-left: 0px;
    }
}

@media (min-width:1300px) {

    .home-offers .rec-carousel-item-prev {
        margin-left: 125px;
    }

    .home-offers .rec-carousel-item-visible {
        margin-left: -125px;

        .offers-card {
            width: 90% !important;
        }

        .offer-data {
            padding: 40px;
        }
    }

    .home-offers .rec-carousel-item-next {
        margin-left: -125px;
    }

    .home-offers .rec-carousel-wrapper,
    .section.home-offers {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0;
    }

    .home-offers {
        .slick-arrow.slick-prev {
            font-size: 20px;
            left: 5%;
            z-index: 100;

            &:before {
                content: ""
            }
        }

        .slick-arrow.slick-next {
            font-size: 20px;
            right: 5%;
            z-index: 100;

            &:before {
                content: ""
            }
        }

    }


    .home-offers {
        .slick-active {
            display: flex;
            justify-content: space-around;

            .offers-card {
                display: inline-block;
                box-shadow: 1px 2em 4em 0.8em #0a0a0a1a, 0 0px 0px 0px #0a0a0a4a;
                
            }
        }

        .slick-slide>div {
            display: flex;
            justify-content: space-around;
            width: 95%;
            margin: 0 auto;
        }
    }
}

.booking-grp {
    .button {
        width: 165px;
        height: 50px;
        font-size: 18px;
        border-radius: 30px;

        &:hover {
            border: solid 2px var(--primary);
        }

        @media (max-width:1300px) {
            width: 159px;
            height: 50px;
            @include directionSwitch(margin-right, margin-left, 10px);
            font-size: 18px;
            border-radius: 30px;
        }

        @media (max-width:1100px) {
            width: 115px;
            height: 50px;
            @include directionSwitch(margin-right, margin-left, 10px);
            font-size: 15px;
            border-radius: 30px;
        }
      

    }

}

.rec-arrow-left img {
    transform: rotate(-180deg);
}

.home-offers {
    .slick-arrow {
        z-index: 1000;
        transform: translate(0, -164%);
    }

}

@media (max-width:1300px) {
    .slick-arrow::before {
        content: ""
    }

    .slick-prev,
    .slick-next {
        top: 45%;
    }

    .home-offers {
        .rec-arrow {
            padding: 9px;
        }

        .slick-prev {
            left: 25px;
        }

        .slick-next {
            right: 25px;
        }
    }

        }

@media (min-width: 1100px) and (max-width:1300px){
    .section.home-offers{
        padding-bottom:0;
        .section{
            padding-bottom:0;
        }
    }
}


.home-offers .slick-next{
    @include valueSwitch(left, '', 94%);
}