.locations {
    user-select: none;
    margin: 0 1.875em 150px;

    @media (max-width: 1024px) {
        margin: 0 0 126px 0;
    }

    .grid {
        margin: 0 18%;

        @media (max-width: 1440px) {
            margin: 0 10%;
        }

        @media (max-width: 1024px) {
            margin: 0 20px;
        }
    }

    .Jumbotron_jumbotron__-cxEF {
        padding-left: 17.5%;
        margin-bottom: 58px;

        @media (max-width: 1440px) {
            height: auto;
            padding-left: 9.5%;
        }

        @media (max-width: 1024px) {
            border-radius: 2rem 2rem 0 0;
            padding-left: 20px;
        }

        .Jumbotron_title__32FaA {
            @media (max-width: 1024px) {
                font-size: 30px;
            }
        }

        p {
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }
    }

    &-heading {
        font-size: 42px;
        font-weight: 500;
        color: var(--text-black);
        text-align: center;
        margin-bottom: 87px;
        margin-top: 50px;

        @media (max-width: 1024px) {
            font-size: 30px;
        }
    }
}

.locations-card-wrapper {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(2, 5fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    justify-items: center;

    @media screen and (max-width:550px) {

        grid-template-columns: repeat(1, 5fr);
    }
}