.radial-chart-card {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  margin-top: 24px;
  h4 {
    border-bottom: 1px solid var(--light-body-color);
    padding: 22px 24px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .radial-chart-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
