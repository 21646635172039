.dashboard-main {
    background-color: #f2f5f6;
    padding: 24px;
    max-width: 93%;
    margin: 0 auto;
    border-radius: 30px;
    margin-top: 40px;
    overflow: hidden;
    @media (min-width: 2000px) {
        max-width: 1920px;
    }
    @media (max-width: 1280px) {
        margin: 0 5%;
    }
    @media (max-width: 425px) {
        margin: 0;
        max-width: 100%;
    }
    .skeleton-item {
        width: 100%;
    }
    .filter-wrap {
        width: 100%;
        height: 94px;
        border-radius: 10px;
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);
        background-color: #fff;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        padding: 23px 26px;
        .field-wrapper {
            display: flex;
            align-items: center;
            span {
                font-size: 16px;
                font-weight: 500;
                color: #53575e;
                margin-left: 10px;
                padding-bottom: 1px;
            }
        }
        .search-btn {
            width: 93px;
            height: 40px;
            margin: 0 0 0 32px;
            padding: 10px 20px 12px;
            border-radius: 30px;
            background-color: #41b9e6;
            color: white;
            border: none;
            font-size: 16px;
            cursor: pointer;
        }
    }
    .db-card-wrap {
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;
    }
    .db-rating {
        display: flex;
        justify-content: space-around;
    }
    @media only screen and (max-width: 1279px) {
        .filter-wrap{
            flex-direction: column;
            padding: 0;
            height: 100%;
            width: 100%;
            padding-top: 15px;
            .field-wrapper {
                display: flex;
            }
            .search-btn {
                width: 90%;
                margin: 15px;
            }
           .multiSelectOptions.dropdown {
            .button {
                padding-right: 30px;
            }
           }
        }
        .db-card-wrap {
            flex-direction: column;
        }
        .db-rating {
            flex-direction: column;
        }
    }

    @media only screen and (max-width: 1024px) {
        .field-wrapper {
            flex-direction: row;
            width: 100%;
        }

        .date-range {
            flex-direction: row;
            margin: 10px 60px 0 45px;
            width: 100%;
        }

        .date-range input {
            width: 100%;
        }
    }

    @media only screen and (max-width: 768px) {
        .date-range {
            margin: 10px 50px 0 32px;
        }
    }

    @media only screen and (max-width: 425px) {
        .field-wrapper {
            flex-direction: column;
        }
        
        .date-range {
            flex-direction: column;
            margin: 0;
            margin-top: 10px;
            padding: 0 20px;
        }

        .filter-wrap .search-btn {
            width: 100%;
        }
    }

}

.multiselect-units {
    flex: 2;
}
