@import "../../../../index.scss";
.booking-title {
    color: #53575e;
    font-weight: 500;
    font-size: 15px;
}

.booking-subtitle {
    font-size: 15px;
    font-weight: 400;
    margin-top: 6px;
    color: var(--blue);
}

.extra-bottom {

    margin-bottom: 8px;
}

.booking-res {
    color: #060709;
    font-weight: 500;
    font-size: 15px;
}

.booking-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @media (max-width:767px) {
        &.check-wrap {
            display: flex;
            margin-top: 5px;
        }

        .wishlist-name {
            width: 100%;
        }

        &.booking-wrapper {
            display: block;

            .booking-right-content {
                align-items: flex-start;
            }
        }
    }
}

.end {

    align-items: flex-end;
}

.booking-price {
    color: #060709;
    font-size: 21px;
    font-weight: 500;

    @media (max-width:1024px) {
        font-size: 16px;
    }

    span {
        font-weight: 400;
    }

}

.booking {
    &-booked-date {
        color: #f05c25;
        font-weight: 400;
        font-size: 14px;
    }

    &-invoice {
        a {
            color: #41b9e6;
            font-weight: 500;
            font-size: 15px;
            text-decoration: underline;

        }
    }

    &-right-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        min-width: 196px;
    }

    &-dates {
       @include directionSwitch(margin-right,margin-left, 20px);
    }
}

.visiblity-vis {
    visibility: visible;
}

.visiblity-hidden {
    visibility: hidden;
}

.show-div {
    display: block;
}

.hide-div {
    display: none;
}

.mt-14p {
    margin-top: 14px;
}

.cancelled,
.active,
.departed,
.arrived {
    &-img {
        position: absolute;
        top: 0px;
        left: 0px;
    }

    &-text {
        background-color: #57b95a;
        padding: 2px 5px 2px 10px;
        display: block;
    }

    &-wrap {
        background: url("../../../../assets/images/active.png") top right no-repeat;
        position: absolute;
        left: 0px;
        top: 23px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        padding-right: 13px;
    }
}

.cancelled {
    &-text {
        background-color: #e52027;
        min-width: 80px;
    }

    &-wrap {
        background-image: url(../../../../assets/images/cancel.png);
        background-size: 15%;
    }
}

.departed {
    &-text {
        background-color: #dae907;
        min-width: 80px;
    }

    &-wrap {
        background-image: url(../../../../assets/images/departed.png);
        background-size: 15%;
    }
}

.arrived {
    &-text {
        background-color: #04441f;
        min-width: 80px;
    }

    &-wrap {
        background-image: url(../../../../assets/images/arrived.png);
        background-size: 15%;
    }
}

.bookings-profile {
    .card-spacer {
        margin-top: 20px;
    }

    .placeholder-image-style {
        height: 177px;
    }

    .booking-content {
        margin-top: 5px;
        margin-left: 10px;
    }

    .booking-invoice {
        &.download {
            text-align: right;
            margin-top: 22px;
            position: relative;

            @media (max-width:767px) {
                margin-top: 10px;
            }

            span {
                background: url("../../../../assets/images/view.png") top left no-repeat;
                color: #53575e;
                font-size: 14px;
                padding-left: 20px;
                font-weight: 500;
                text-decoration: underline;
                cursor: pointer;

                @media (max-width:767px) {
                    position: absolute;
                    @include directionSwitch (right,left, 20px);
                    @include valueSwitch(top,-41px,-27px);
                }
            }
        }

    }

    .book-btn-wrap {}

    .book-btn {
        width: auto;
        margin: 15px 0 0 0 !important;

        @media (max-width:767px) {
            font-size: 11px;
            padding: 0 20px;
        }
    }
}

.cancel-btn {
    @media (min-width:767px) {
        button {
            width: 140px !important;

        }
    }

    @media (max-width:767px) {
        button {
            width: 110px !important;
        }
    }
}

.room-type {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3b62ac;
}