.popup {
    margin: 40% auto 40px;
    width: 80%;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    padding: 2rem;

    .heading {
        color: var(--text-black);
        font-size: 1.75em;
        font-weight: 500;
        text-transform: none;
        letter-spacing: unset;
        margin-bottom: 1em;
    }

    &-btn,
    &-continue-btn {
        height: 52px;
        border-radius: 30px;
        background-color: var(--primary);
        border: none;
        font-size: 1em;
        font-weight: 500;
        text-transform: uppercase;
        width: 100%;
        color: var(--white);
        cursor: pointer;
    }

    &-continue-btn {
        margin-top: 1em;
        background-color: var(--white);
        font-size: 0.8em;
        color: var(--primary);
    }
}
