.toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin-top: 10px;
  flex-flow: wrap;
}
.owner-scheduler {
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 98%;
    margin: auto;
    flex-wrap: wrap;
    align-items: flex-start;
    .toggle-wrapper-owner nav {
      height: 42px;
    }
  }
  &-table-header {
    width: 98%;
    margin: auto;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
  }
  &-datepicker,
  .owner-search {
    position: relative;
    background: white;
    padding: 7px 14px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: #cccccc;
    min-height: 42px;
  }
  &-datepicker > div {
    position: absolute;
    z-index: 100;
    left: 0px;
    top: 43px;
    .react-datepicker__header {
      background-color: white;
    }
  }
}
.react-datepicker__header--custom {
  div {
    button {
      background: #4ab9e6;
      padding: 7px;
      color: white;
      font-weight: bold;
      outline: none;
      /* background: none; */
      /* color: inherit; */
      border: none;
      /* padding: 0; */
      /* font: inherit; */
      cursor: pointer;
      outline: inherit;
      border-radius: 5px;
      margin-right: 5px;
      margin-left: 5px;
    }
    select {
      margin-right: 5px;
    }
  }
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background: #4ab9e6 !important;
}
.owner-datepicker-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.hide-icon {
  display: none;
}
.show-icon {
  display: inherit;
}
.owner-reservation-units {
  min-width: 550px;
  @media (max-width: 1440px) {
    min-width: 360px;
  }
  @media (max-width: 1280px) {
    min-width: 340px;
  }
  @media (max-width: 425px) {
    min-width: auto;
  }
  @media (max-width: 900px) {
    width: 60%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  .tag {
    margin-right: 10px;
  }
}
.datepicker-value {
  font-size: 14px;
}
.owner-reservation-main {
  .search-btn,
  .clear-all-table {
    width: 93px;
    height: 42px;
    margin: 0;
    padding: 10px 20px 12px;
    border-radius: 30px;
    background-color: #41b9e6;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    @media (max-width: 768px) {
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 15px;
    }
  }
   min-height: 400px;
  .clear-all-table {
    width: auto;
    background-color: red;
    margin-left: 10px;
  }
  .scheduler-wrap .no-result-coupon {
    margin: 0 18px !important;
    margin-top: 10px !important;
    border-radius: 1rem;
    border: 1px solid var(--border-gray);
  }
}
.owner-search {
  background: white;
  padding: 7px 14px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  height: 42px;
  width: 205px;
  margin-right: 20px;

  @media (min-width: 1400px) {
    margin-right: 3%;
  }
  @media (min-width: 1600px) {
    margin-right: 4%;
  }
  @media (min-width: 1900px) {
    margin-right: 5%;
  }
}
.multiSelectOptions.dropdown {
  .button {
    padding-right: 30px;
    @media (max-width: 700px) {
      min-width: 300px;
      font-size: 12px;
      padding: 5px;
    }
  }
  .fa-close {
    position: relative;
    width: 15px;
    height: 16px;
    &:before {
      position: absolute;
      top: 2px;
      left: 3px;
    }
  }
}
.fa-unsorted:before,
.fa-sort:before {
  font-size: 13px;
}
.owner-reservation-main {
  .search-btn,
  .clear-all-table {
    @media (max-width: 700px) {
      min-width: 65px;
      width: auto;
      padding: 10px 20px;
    }
  }
}
.owner-scheduler-table-header .btn-wrap {
  width: 76%;
  @media (min-width: 1801px) {
    width: 79%;
    margin-right: 15px;
  }
  @media (max-width: 700px) {
    margin-top: 10px;
  }
}
.owner-reservation-units.hide {
  display: none;
  visibility: visible;
}
.owner-reservation-units.show {
  display: block;
}
