.owner-reservation {
  .item {
    padding: 8px 26px;
  }
}
.owner-dropdown {
  position: relative;
  min-width: 300px;
  cursor: pointer;
  @media (max-width: 1440px) {
    min-width: 255px;
  }
}
.owner-reservation-selection {
  position: relative;
  #dropdown-menu {
    position: absolute;
    top: 40px;
    width: 100%;
    z-index: 10;
  }
  .search-dropdown {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
    .active {
      background-color: #f1fafe;
    }
  }
  .toggle-icon {
    position: absolute;
    right: 10px;
    top: 12px;
  }
}
