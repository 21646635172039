.jumbotron {
    background-image: url(../../assets//images//services/slice.png);
    width: 100%;
    font-size: 1.0em;
    padding: 20px 15vw!important;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 30px;
    text-align: center;
    @media (max-width: 425px) {
        border-radius: 30px 30px 0 0;
        padding: 20px !important;
    }

    .title {
        color: white;
        font-size: 52px;
        font-weight: 500;
        text-transform: uppercase;
        @media (max-width: 425px) {
            font-size: 20px;
        }
    }

    .subtitle {
        @media (max-width: 425px) {
            font-size: 14px;
        }
    }
}