.switch-to-owner{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 21.4px;
    .icon{
        margin-right: 9.8px;
        margin-left: 9.8px;
    }
    .button-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        .top-content{
            font-size: 12px;
            white-space: nowrap;
            color: var(--text-gray);
        }
        .owner-content{
            font-size: 14px;
            color: var(--text-black);
            font-weight: 500;
        }
    }
}