@keyframes loading {
    0% {
      transform: translateX(0);
    }
    50%,
    100% {
      transform: translateX(460px);
    }
  }
  
  .skeleton-item {
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding: 20px;
    border-radius: 4px;
    position: relative;
  }
  
  .skeleton-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 100%;
    background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
    animation: loading 2s infinite linear;
  }
  
  .skeleton-img {
    width: 150px;
    height: 150px;
    background: #f2f2f2;
    position: relative;
    overflow: hidden;
  }
  
  .skeleton-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
  }
  
  .skeleton-name {
    width: 70%;
    height: 18px;
    background: #f2f2f2;
    position: relative;
    overflow: hidden;
  }
  
  .skeleton-name::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 100%;
    background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
    animation: loading 2s infinite linear;
  }
  
  .skeleton-email {
    width: 85%;
    height: 18px;
    background: #f2f2f2;
    margin-top: 3px;
    position: relative;
    overflow: hidden;
  }
  
  .skeleton-email::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 100%;
    background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
    animation: loading 2s infinite linear;
  }