@import "../../index.scss";

.toast {
    display: flex;
    margin: 6px;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    padding: 16px 30.4px 16px 27px;
    position: fixed;
    bottom: 33px;
    right: 4px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    animation: slide-in 0.5s;
    background-color: var(--white);
    z-index: 99999999999999999999;

    @media (max-width: 425px) {
        padding: 10px 20.4px 10px 17px;
    }
    @media (max-width: 375px) {
        width: 90%;
        right: 0;
        margin: 0 5%;
    }

    &-wrapper {
        visibility: visible;
    }

    &-button {
        order: 3;
        height: fit-content;
        outline: none;
        border: none;
        color: var(--gray);
        font-weight: 500;
        background-color: transparent;
        pointer-events: unset;
        cursor: pointer;
    }

    &-image {
        order: 1;
        height: 40px;
        width: 40px;
        @include directionSwitch(margin-right, margin-left, 18px);
        @media (max-width: 768px) {
            height: 36px;
            width: 36px;
            @include directionSwitch(margin-right, margin-left, 12px);
        }

        img {
            height: inherit;
            width: inherit;
            @media (max-width: 768px) {
                height: 36px;
                width: 36px;
            }
        }
    }

    &-message {
        order: 2;
        width: fit-content;
        max-width: 355px;
        @include directionSwitch(margin-right, margin-left, 30px);
        word-break: break-word;
        font-size: 16px;
        font-weight: 500;
        font-weight: normal;
        color: var(--gray);
        @media (max-width: 768px) {
            font-size: 16px;
            font-weight: normal;
            width: 250px;
            color: var(--text-black);
        }
        @media (max-width: 425px) {
            width: 180px;
        }
        @media (max-width: 325px) {
            font-size: 14px;
            width: 140px;
        }
    }

    &-close {
        opacity: 0;
        visibility: hidden;
        display: none;
        transition: all 0.5s ease;
    }
}

@keyframes slide-in {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

// Styles for toast
@mixin colored-toast($borderColor, $backgroundColor) {
    @extend .toast;
    border-left: 7px solid $borderColor;
}

.toast-success {
    @include colored-toast(#3cb75d, #effaf2);
}

.toast-info {
    @include colored-toast(#026ade, #e4effb);
}

.toast-warning {
    @include colored-toast(#ef9400, #fdf3e4);
}

.toast-danger {
    @include colored-toast(#f35d5d, #fdeded);
}
