.monthly-deal-wrapper {
    margin: auto 6%;
    border-radius: 50px;
    background-color: var(--light-bg-color);

    @media (max-width: 540px) {
        margin: auto 0;
    }
}

.monthly-layout-grid {
    margin: 0 11.5% 20px;
    padding-top: 10px;
    padding-bottom: 18px;
   .slick-dots{
        margin-top: 10px !important;
    }
    @media (max-width: 768px) {
        margin-right: 0;
        margin-left: 0;
    }

    @media (max-width: 540px) {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    .offers-inner-wrapper {
        position: relative;
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
        display: none !important;
    }

    .deal-img {
        height: 245px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .category {
            position: absolute;
            top: 0;
            padding: 15px 43px;
            background-color: var(--secondary);
            color: var(--white);
            font-size: 18px;
            font-weight: 500;
            border-radius: 30px 0 30px;
            text-transform: capitalize;

            @media (max-width: 540px) {
                padding: 19px 18px;
                font-size: 20px;
            }
        }
    }

    .desc-green {
        color: var(--primary-green);
        margin-top: 5px;
        @media (max-width: 425px) {
            font-size: smaller;
        }
    }

    .slick-slide>div {
        width: 95%;
        min-height: 460px;
        margin: 20px;
        border-radius: 30px;
        overflow: hidden;
        background-color: var(--white);
        transition: 0.5s;
        box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.09);

        @media (max-width: 425px) {
        }

        &:hover {
            transform: scale(1.03);
        }
    }

    .slick-slide.slick-active.slick-current {
        @media (max-width: 1024px) {
            >div {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .deal-content {
        padding: 20px 25px 25px;

        @media (max-width: 375px) {
            padding: 25px 18px;
        }

        .text-separator::before {
            margin: 0;
            content: "";
        }

        >div {
            display: flex;
            align-items: center;

            @media (max-width: 425px) {
                align-items: baseline;
            }

            .timer {
                align-self: flex-end;
                margin-bottom: -4px;
            }

            .timer-icon {
                margin-top: 0;
            }
        }
    }
}

.deal-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--secondary);
}

.deal-subtitle {
    font-size: 16px;
    color: var(--gray);
}

.deal-text {
    font-size: 20px;
    font-weight: 600;
    color: var(--gray);
    margin-right: 5px;
}

.deal-offer {
    font-size: 20px;
    font-weight: 600;
    color: var(--orange);
}

.single-card-align {
    .slick-track {
        @media (min-width: 768px) {
            margin-left: 0;
        }

        div:focus {
            outline: none;
        }
    }

    .slick-slide.slick-active.slick-current {
        width: 95vw !important;
    }
}

.multiple-cards {
    .slick-track {
        display: flex;
    }

    .slick-slide.slick-active.slick-current {
        @media (max-width: 375px) {
            width: 83vw !important;
        }
    }
}

.offers-wrapper {
    cursor: pointer;
}