@import "../../index.scss";

.top-bar {
  .content-wrap {
    max-width: 76.875rem;
    margin: 0 auto;
  }

  border-top-left-radius: 30px 30px;
  border-top-right-radius: 30px 30px;

  @media only screen and (min-width: 1024px) {
    margin: 1em;
    border-radius: 30px;
  }

  background-color: #138d5c;
  background-image: url("../../assets/images/profile-bg.png");
  color: var(--white);
  .top-content {
    color: var(--white);
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding: 1rem 2rem 0;

    .con-title {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 500;
      padding: 0 !important;
    }

    .points-earned {
      margin: -1em;

      .coin-wrap {
        position: relative;
        text-align: end;
        @media (max-width: 768px) {
          width: 55%;
          margin: 0 auto;
          margin-top: 20px;
          
        }
        @media (max-width: 425px) {
          width: 100%;
        }

        .coin-image {
          height: 5rem;
        }

        .coin-content {
          backdrop-filter: blur(2px);
          display: flex;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          top: 2em;
          left: 1em;
          width: 18.438rem;
          height: 2.375rem;
          border-radius: 30px;
          box-shadow: 0 9px 14px 0 rgba(19, 141, 92, 0.14);
          background-color: var(--text-black);
          background-color: rgba(6, 7, 9, 0.5);
          position: absolute;
          @media(max-width:768px){
            border-radius: 12px;
          }

          .text-white {
            font-size: 14px;
            font-weight: 500;
            margin: 0.5rem;
          }

          .text-yellow {
            color: #ffe11c;
            font-size: 12px;
            font-weight: 500;
            margin-right: 0.5rem;

            span {
              font-size: 16px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      flex-direction: row;
      align-items: center;
      padding: 2rem 1em 2rem;
      justify-content: space-between;

      .con-title {
        font-size: 36px;
        width: 100%;
      }

      .points-earned {
        .coin-wrap {
          .coin-image {
            height: 120px;
          }

          .coin-content {
            flex-direction: column;
            top: 0;
            width: 100%;
            width: 13.75rem;
            height: 7.813rem;
            @include valueSwitch(left, -9.5em, 9.5em);

            .text-white {
              font-size: 16px;
            }

            .text-yellow {
              font-size: 20px;

              span {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  .tabs-for-mobile {
    .tabs-section {
      overflow-x: scroll;
    }

    * {
      color: var(--white);
      @media only screen and (min-width: 1024px) {
        display: none;
      }
    }
  }
  .center-x {
    justify-content: center;
  }
}

.loyalty-loader {
  background-color: rgba(6, 7, 9, 0.5);
  position: relative;
  height: 100px;
  width: 150px;
  border-radius: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 65%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(6, 7, 9, 0.5),
      rgba(23, 28, 41, 0.342),
      rgba(6, 7, 9, 0.5)
    );
    border-radius: 1rem;
    animation: slide-5 2s infinite linear;
  }
}

@mixin custom-width-loading($name) {
  @keyframes #{$name} {
    0% {
      width: 25%;
    }
    50%,
    100% {
      width: 100%
    }
  }
}
@include custom-width-loading(slide-5);
