.report-grid {
    margin: 0 auto;
    max-width: 90%;
    display: flex;
    justify-content: space-between;
    @media (min-width: 2000px) {
        max-width: 1920px;
    }
    @media (max-width: 1280px) {
        margin: 0 7%;
    }
    @media (max-width: 1024px) {
        flex-direction: column;
    }
    @media (max-width: 375px) {
        margin: 0 20px;
    }

    .w-25 {
        width: 23%;
        @media (max-width: 1280px) {
            width: 27%;
        }
        @media (max-width: 1024px) {
            width: 100%;
        }
    }

    .w-75 {
        width: 73%;
        @media (max-width: 1280px) {
            width: 70%;
        }
        @media (max-width: 1024px) {
            width: 100%;
            margin-top: 30px;
        }
    }
}
