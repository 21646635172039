@import "../../../../index.scss";
$heading-color: var(--text-black);

.personal-info {
    .title-action {
        display: flex;
        justify-content: space-between;
        padding: 2.5rem 1.375rem 0;
        align-items: center;
        position: sticky;
        top:0;
        background: #fff;
        border-radius: 30px 30px 0 0;
        z-index: 10;
    }

    .title-name {
        font-size: 1.25rem;
        color: $heading-color;
        font-weight: 600;
    }

    .edit-icon {
        margin-right: 5px;
        width: 11.8px;
        height: 11.8px;
    }

    .profile-edit-icon {
        width: 14.1px;
        height: 14.1px;
        object-fit: contain;
    }

    .edit-btn {
        border-radius: 1.875rem;
        background-color: var(--white);
        border: 2px solid var(--primary);
        min-width: 5.313rem;
        min-height: 2.375rem;
        color: var(--primary);
        cursor: pointer;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 500;

        i {
            font-size: 12px;
            @include directionSwitch(margin-right, margin-left, 0.5rem);
        }
    }

    .profile-img-wrapper {
        display: flex;
        align-items: center;
        padding: 2.5rem 1.375rem 0;

        .img-wrap {
            position: relative;

            .profile-img {
                width: 6.5rem;
                height: 6.5rem;
                @include directionSwitch(margin-right, margin-left, 1.5rem);
                box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.12);
                border-radius: 50%;
                object-fit: cover;
            }

            .profile-edit {
                position: absolute;
                @include valueSwitch(margin, 3px 18px 0 72px, 3px 18px 0 22px);
                padding: 12.1px 11.8px 11.8px 12.1px;
                background-color: var(--white);
                width: 2.375rem;
                height: 2.375rem;
                color: var(--primary);
                bottom: 6px;
                left: -0.5rem;
                border-color: transparent;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        h2 {
            font-size: 18px;
            font-weight: 500;
            color: $heading-color;
        }
    }

    .form-wrap {
        padding: 2.5rem 1.375rem 4.5rem;

        .form-field {
            margin-bottom: 30px;
        }

        .btn-wrap {
            display: flex;
            align-items: center;
            max-height: 48px;
            justify-content: space-between;
        }

        input {
            width: 100%;
            min-height: 48px;
        }

        .btn-green {
            border-color: transparent;
            border-radius: 24px;
            background-color: var(--light-bg-color);
            font-size: 14px;
            font-weight: 500;
            color: var(--primary);
            cursor: pointer;
            padding: 12px 23px;
            white-space: nowrap;

            i {
                @include directionSwitch(margin-right, margin-left, 5px);
            }

            &:disabled {
                cursor: not-allowed;
            }
        }

        .verified {
            border-color: transparent;
            border-radius: 24px;
            background-color: transparent;
            font-size: 14px;
            font-weight: 500;
            color: #138d5c;
            padding: 12px 23px;
            white-space: nowrap;

            i {
                @include directionSwitch(margin-right, margin-left, 5px);
            }
        }

        .action-btn-wrap {
            display: flex;
            justify-content: space-around;
        }
    }

    .cancel-btn {
        cursor: pointer;
        border-color: transparent;
        background-color: transparent;
        color: var(--text-gray);
        font-size: 14px;
        font-weight: 500;
        @include directionSwitch(margin-right, margin-left, 17px);
    }

    .save-btn {
        cursor: pointer;
        background-color: var(--primary);
        border-radius: 30px;
        border-color: transparent;
        color: white;
        padding: 0.8rem 1.5rem;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;

        i {
            @include directionSwitch(margin-right, margin-left, 9px);
        }
    }

    .btn-action-desk {
        display: none;
    }

    .remove-photo {
        cursor: pointer;
        border-color: transparent;
        background-color: transparent;
        color: #ec0606;
        font-size: 14px;
        font-weight: 500;
        margin-right: 17px;
        position: absolute;
        top: 7rem;
        left: 7px;
        white-space: nowrap;
    }

    @media only screen and (min-width: 1024px) {
        border-radius: 30px;
        border: solid 2px var(--border-light-gray);
        margin-bottom: 6.25rem;

        .title-action {
            border-bottom: solid 2px var(--border-light-gray);
            padding: 1.813rem 2.625rem;
        }

        .edit-btn {
            min-width: 97px;
            min-height: 42px;
        }

        .remove-photo {
            top: 10rem;
            @include valueSwitch(left, 15px, 65px);
        }

        .profile-img-wrapper {
            padding: 2.625rem 2.625rem 3.625rem;

            .img-wrap {
                .profile-img {
                    width: 150px;
                    height: 150px;
                    margin: 0 32px 0 0;
                    box-shadow: 0 10px 26px 0 rgba(0, 0, 0, 0.13);
                }

                .profile-edit {
                    left: 1.5rem;
                }
            }

            h2 {
                font-size: 20px;
            }
        }

        .form-wrap {
            padding: 0 2.625rem 4.5rem;

            .form-field {
                margin-bottom: 38px;

                input {
                    width: 438px;
                }
            }

            .btn-green {
                margin: 2.688rem 2.875rem 2.563rem 2rem;
            }

            .verified {
                margin: 2.688rem 2.875rem 2.563rem 2rem;
            }

            .btn-wrap {
                justify-content: unset;
            }

            .action-btn-wrap {
                display: none;
            }
        }

        .btn-action-desk {
            display: flex;
        }
    }
    .profile-phone{
        width: 100%;
    }

    .react-tel-input .form-control {
        border-width: 2px;
    }
}

.loading-spin {
    text-align: center;
    align-items: center;
}

.disabled-input {
    pointer-events: none;

    .flag-dropdown {
        background-color: #f2f2f2 !important;
        border-color: #e8e8e8 !important;
    }

    .special-label {
        display: none;
    }

    .form-control {
        border-color: #e8e8e8 !important;
    }
}

.profile-phone {
    display: inline-block;
    margin-bottom: 33px !important;
}

.locations-dropdown {
    position: absolute;
    background-color: white;
    width: 100%;
    border: 1px olid var(--border-gray);
    z-index: 15;
    box-shadow: 0 0 1px var(--text-black);
    border-radius: 0 0 6px 6px;
    top: 54px;
    width: 438px;
    @media (max-width: 1023px) {
        width: 100%;
    }

    &-item {
        cursor: pointer;
        padding: 10px 15px;
        &:hover {
            background-color: var(--light-bg-color);
        }

        h3 {
            font-size: 14px;
            color: #4a4a4a;
            font-weight: 500;
        }

        h6 {
            font-size: 14px;
            color: gray;
        }
    }
}