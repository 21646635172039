.auth-layout {
    min-height: 100vh;
    overflow-y: hidden;
    
    .columns {
        // Mobile breakpoint
        @media (max-width: 768px) {
            margin: 0 !important;
        }
    }
    .column {
        padding: 0;
    }
    .left {
        // Mobile breakpoint
        @media (max-width: 1024px) {
            display: none;
        }
    }

    .image-wrapper {
        border-radius: 0 0 20em 0;
        background-image: url('../../../assets/images/auth-bg.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .image {
        width: 100%;
        width: -moz-available; /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
        width: stretch;
        height: max-content;
    }

    .form-heading {
        color: var(--text-black);
        display: inline-block;
        font-size: 1.75em;
        font-weight: 500;
        margin-bottom: 1em;
    }

    // Auth Form Styles
    .field {
        &:not(:last-child) {
            margin-bottom: 2.25em;
            @media (max-width: 768px) {
                margin-bottom: 2.8em;
            }
        }
        p:first-letter {
            text-transform: capitalize;
        }
        .control {
            width: 100%;
        }
    }

    .login-btn {
        width: inherit;
        height: 52px;
        border-radius: 30px;
        font-size: 1.125em;
        font-weight: 500;
        text-transform: uppercase;
        &.is-link {
            background-color: var(--primary);
            &:hover {
                background-color: var(--primary);
                box-shadow: inset 0 0 0.5em rgba(10, 10, 10, 0.1);
            }
        }
    }

    .button.is-link:focus:not(:active), 
    .button.is-link.is-focused:not(:active) {
        box-shadow: none;
    }

    // Links to forgot password
    .links {
        color: var(--blue);
        font-size: 0.875em;
        font-weight: 400;
    }
}

.staycae-logo {
    width: 262px;
    height: 61px;
    margin-bottom: 52px;
    &-wrapper {
        display: flex;
        justify-content: center;
    }
}

.content-area {
    margin: 52px 20% 0;
    > div {
        padding: 0.375em;
    }
    @media (max-width: 991px) {
        margin: 52px 10%;
    }
    @media (max-width: 425px) {
        margin: 52px 5%;
    }
}
