#not-found {
    max-width: 675px;
    color: #53575e;
    text-align: center;
    padding: 50px 0 150px;
    @media (max-width: 768px){
       width: 82%;
       margin: 0 auto;
      flex-flow: column;
    }
       
    h2 {
        font-size: 101px;
        font-weight: 600;
        line-height: 1.1;
      }
      h6{
        font-size: 22px;
        font-weight: 600;
      }
      p{
        color: #9e9e9e;
        line-height: 1.5;
        font-size: 16px;
        padding: 30px 0 40px;
      }
      button{
        font-size: 18px;
        font-weight: 500;
        width: 177px;
        border-radius: 30px;
      }
      img{
          width: auto;
      }
}