.add-property {
    background-color: transparent;
    padding: 0;
    .list-property-grid {
        max-width: 96%;
        @media (min-width: 2000px) {
            max-width: 1920px;
        }
    }

    .list-property-wrapper {
        margin-top: 0;
        margin-bottom: 20px;
    }

    @media (max-width: 1280px) {
        .list-property-grid {
            margin: 0 5%;
        }
    }

    @media (max-width: 425px) {
        padding: 10px;
        
        .list-property-grid {
            margin: 0;
            max-width: 100%;
        }
    }
}

@media (max-width: 1024px) {
    .order-1 {
        order: 1;
    }
}
