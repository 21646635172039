@import "../../index.scss";
.detail-wrapper {
    display: flex;
    width: 100%;
    padding: 1em;
    .right-content {
        width: 100%;
        @media only screen and (min-width: 1024px) {
            width: calc(100% - 24.375);
            @include directionSwitch (padding-left,padding-right, 1.875rem);
        }
    }
    .left-content {
        max-width: 21.375rem;
        width: 100%;
        @media only screen and (max-width: 1023px) {
            display: none;
        }
    }
}

.content-wrap {
    max-width: 76.875rem;
    margin: 0 auto;
}

.profile-wrapper {
    margin-bottom: 100px;
}
