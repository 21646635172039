

.register-link{
    font-size: 14px;
    text-align: center;
    margin: 5%;
    margin-top: 2em;
    font-weight: 400;
    span {
        color: var(--secondary);
    }
    @media only screen and (min-width: 1024px) {
        margin-top: 4em;
    }
}

.forget-pwd-link{
    color: var(--secondary);
    font-size: 14px;
    margin-top: -1.25em;
    margin-bottom: 1.5rem;
    text-align: end;
}
