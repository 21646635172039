@import "../../../../styles/_common.scss";

.section.home-testimonials {
    font-size: 1.3em;
    max-width: 80%;
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto;
    border-radius: 30px;
    padding-top: 20px;

    @media (min-width: 1400px) {
        max-width: 80%;
    }

    @media (min-width: 1700px) {
        max-width: 75%;
    }

    @media (max-width:770px) {
        max-width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .all-title {
        margin-top: 45px;
        margin-bottom: -40px;

        @media (max-width:770px) {
            padding: 30px;
            margin-top: 0px;
            margin-bottom: 30px;
            line-height: 1.2;
        }
    }

    .testimonial-content {
        background-size: cover;
        padding: 30px;
        height: 300px;
        border-radius: 30px;
        line-height: 1.4;
        color: #060709;
        margin-right: 2%;
        min-width: unset;

        @media screen and (max-width:450px) {
            min-width: 310px;
        }

        .comments {
            color: #555;
        }

        .stay-txt {
            color: #53575e;
        }
    }

    .rec-slider-container {
        overflow: hidden;
        position: relative;
    }

    .rec-arrow {
        display: none;
    }

    .rec-dot {
        @extend .dot-button;
    }

    .slick-track {
        .slick-active {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }

        div {
            :focus {
                outline: none;
            }
        }
    }

    .slick-dots {

        .slick-active {
            button {
                @extend .dot-active;
                border-radius: 10px;
                padding: 0px;

            }

            button::before {
                content: '';
            }

        }

        li:not(.slick-active) {
            button {
                @extend .dot-button;
                padding: 0px;

            }

            button::before {
                content: '';
            }
        }
    }


    .stay {
        color: var(--secondary);
    }

    .stay-content {
        margin-bottom: 5px !important;
    }

    .stay-content,
    .testimonial-about {
        font-size: 0.6em;
    }

    .lowerQuotes {
        transform: rotate(180deg);
        position: absolute;
        right: 0;
        bottom: 65px;

        @media (max-width: 768px) {
            max-width: 48px;
            right: 15px;
        }
    }

    .upperQuotes {
        @media (max-width: 768px) {
            position: absolute;
            top: 95px;
            max-width: 48px;
            left: 10px;
        }
    }

    .slick-list {

        @media (max-width:770px) {
            padding: 0px;
        }

    }

    .slick-current {

        display: flex;
        flex-direction: row;
        justify-content: space-around;

        @media (max-width:770px) {
            max-width: 410px;
        }

        @media (max-width:450px) {
            max-width: 365px;
        }
    }

    .slick-dots {
        margin-top: 20px;
        top: 100%;
    }

    .slick-slider {
        margin-bottom: 130px;
    }
    .slick-slide > div{
       width: 100%;
    }
}

.flex-start-align {
    display: flex;
    align-items: center;
}

.user-img-testi {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.review-title {
    font-size: 18px;
}