
@import "../../index.scss";
.register-screen {
    .row {
        margin-bottom: 2.25em;
        display: flex;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        >div:first-child {
           margin-right: 32px;
           @media (max-width: 768px) {
                margin-right: 0;
            }
        }
         .field {
            width: 100%;
        }

        .field:not(:last-child) {
            margin-bottom: 4px;

            @media (max-width: 768px) {
                margin-bottom: 2em;
            }
        }
       
    }
}

.flag-dropdown {
    border: 2px solid #d9d9d9 !important;
    @include directionSwitch(border-right,border-left, none!important);
    @include valueSwitch (border-right,2px !important, none !important);
     .selected-flag {
         border-radius: 5px !important;
        @include directionSwitch(border-left, border-right, 2px solid #dbdbdb);
        @include valueSwitch("border-left-width",0px,2px);
        @include directionSwitch (padding-left,padding-right,11px);
        .arrow{
            @include directionSwitch(left, right, 29px);
        }
     }
}
.react-tel-input{
    .form-control{
        border-color: var(--border-gray) !important;
        @include directionSwitch(padding-left, padding-right, 58px)
    }
}
.special-label {
   @include directionSwitch(left,right,1em !important);
   @include valueSwitch("left",25px, auto !important);
    z-index: 20 !important;
}

.react-tel-input .form-control:focus {
    box-shadow: none !important;
    border-color: var(--primary) !important;

    .special-label {
        color: var(--primary)
    }
}


.apply-error .form-control,
.apply-error .form-control:hover {
    box-shadow: none !important;
    border-color: #f14668 !important;

    .special-label {
        color: var(--primary)
    }
}

.react-tel-input:focus-within .special-label,
.react-tel-input:active .special-label,
.react-tel-input:target .special-label {
    color: var(--primary)
}

.apply-error .special-label,
.apply-error .special-label,
.apply-error .special-label {
    color: #f14668
}



.react-tel-input input:active {
    box-shadow: none !important;
    border-color: none !important;

    .special-label {
        color: var(--primary)
    }

    .flag-dropdown {
        border: 2px solid var(--primary) !important;
        @include directionSwitch(border-right, border-left, none !important);
    }
}

.flag-dropdown {
    background-color: white !important;
}

.apply-error .special-label {
    color: #f14668 !important;
}

.phone-no {
    border: 2px solid var(--border-gray) !important;
}

.react-tel-input:hover .flag-dropdown {
    border-color: #b5b5b5 !important;

}
.react-tel-input:focus-within .flag-dropdown,
.react-tel-input:active .flag-dropdown,
.react-tel-input:target .flag-dropdown {
    border: 2px solid var(--primary) !important;
    @include directionSwitch(border-right, border-left, none !important);
}

.apply-error .flag-dropdown,
.apply-error:focus-within .flag-dropdown,
.apply-error:hover .flag-dropdown {
    border: 2px solid #f14668 !important;
    @include directionSwitch(border-right, border-left, none !important);
}
.reg-first-child{
    @include directionSwitch(margin-right,margin-left,32px);
    @include valueSwitch(margin-right,32px,0 !important);
}