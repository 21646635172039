.pdf-viewer {
    border-radius: 30px;
    border: 2px solid var(--border-light-gray);
    padding: 25px;
    margin-bottom: 40px;
    background-color: white;
    min-height: 435px;

    .section-title {
        font-size: 18px;
        font-weight: 500;
        text-transform: capitalize;
        color: var(--gray);
        @media (max-width: 425px) {
            font-size: 16px;
        }
    }

    .header-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .divider {
        border: 1px dashed var(--border-light-gray);
        display: block;
        margin-top: 6px;
    }

    .download-btn,
    .email-btn {
        cursor: pointer;
        border: none;
        height: 40px;
        width: fit-content;
        padding: 0 10px;
        outline: transparent;
        font-size: 15px;
        background-color: transparent;
        border-radius: 6px;
        @media (max-width: 1280px) {
            margin-bottom: 10px;
        }
    }

    .download-btn:focus,
    .download-btn:active,
    .email-btn:focus, 
    .email-btn:active,
    .download-btn:focus:not(:active),
    .email-btn:focus:not(:active) {
        border-color: var(--border-light-gray);
        box-shadow: none;
    }

    .download-btn {
        background-color: var(--primary);
        color: var(--white);
        border-color: var(--primary);
        padding: 11px;
        &:hover {
            color: var(--white);
            box-shadow: 0 0 3px 0 #41bae69c;
            border-color: var(--primary);
        }
    }

    .email-btn {
        border: 1px solid var(--border-light-gray);
        border-color: var(--border-light-gray);
    }

    .btn-group {
        @media (max-width: 1280px) {
            margin-top: 10px;
        }
    }
}
