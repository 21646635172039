.scheduler {
  .ant-row-flex {
    justify-content: flex-start;
  }
  .ant-radio-group {
    margin-left: 20px;
  }
}
.resource-table {
  td {
    text-align: left;
    margin: 0px;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 79.15px !important;
    border-bottom: none !important;
  }
}
#RBS-Scheduler-root {
  .scheduler-view,
  .resource-view {
    background: white;
  }
}

.header3-text {
  padding-top: 10px;
}
.resource-view {
  div {
    width: 100% !important;
    overflow: visible !important;
  }
}
.scheduler-view {
  width: 75vw !important;
}
.timeline-event{
  margin-left: 45px;
}
