@import "../../index.scss";
.field {
    position: relative;
}

.text-area {
    .has-text-primary {
        color: var(--primary); 
    }

    .is-primary,
    .is-danger {
        &.textarea:focus,
        &.is-focused.textarea,
        &.textarea:active,
        &.is-active.textarea {
            box-shadow: none;
        }
    }   

    .label {
        color: var(--text-gray);
        font-size: 0.75em;
        font-weight: 400;
    }

    .is-primary.textarea {
        border-color: var(--primary);
        box-shadow: none;
    }

    .is-danger.textarea {
        border-color: #f14668;
        box-shadow: none;
    }

    .is-medium.textarea {
        height: 157px;
        padding: 1em 2.5em 0 1.3em;
        font-size: 1rem;
        letter-spacing: 0.3px;
    }

    .hide {
        display: none;
    }

    .show {
        display: inline-block;
        position: absolute;
        top: -0.75em;
       @include directionSwitch(left,right,1em);
        height: fit-content;
        z-index: 1;
        padding: 0 0.5em;
        background-color: var(--white);
        animation: DisplayLabel 0.3s;
        @media(max-width: 450px) {
            font-size: 9px;
        }
    }

    .textarea {
        height: 52px;
        border-radius: 6px;
        border: 2px solid;
        border-color: var(--border-gray);
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--text-gray) !important;
        opacity: 1; /* Firefox */
    }
    
    :-ms-textarea-placeholder { /* Internet Explorer 10-11 */
        color: var(--text-gray) !important;
    }
    
    ::-ms-textarea-placeholder { /* Microsoft Edge */
        color: var(--text-gray) !important;
    }
}
