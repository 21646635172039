.coupons-loader {
    .message-header {
        align-items: center;
        position: relative;
    }

    .coupons-content {
        display: flex;
        align-items: baseline;
    }

    .name {
        height: 25px;
        background-color: #f2f2f2;
        width: 250px;
        margin-right: 20px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 35%;
            height: inherit;
            background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
            animation: loading-progress 2s infinite linear;
        }
    }

    .discount {
        height: 20px;
        background-color: #f2f2f2;
        margin: 10px 0;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 45%;
            height: inherit;
            background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
            animation: loading-progress 2s infinite linear;
        }
    }

    .copy-loader {
        height: 30px;
        width: 30px;
        min-width: 30px;
        border-radius: 30px;
        background-color: #f2f2f2;
    }

    .valid-until {
        height: 15px;
        width: 140px;
        background-color: #f2f2f2;
        margin: 10px 0;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 25%;
            height: inherit;
            background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
            animation: loading-progress 2s infinite linear;
        }
    }

    .date {
        height: 20px;
        width: 200px;
        background-color: #f2f2f2;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 35%;
            height: inherit;
            background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
            animation: loading-progress 2s infinite linear;
        }
    }

    .play-button {
        pointer-events: none;
        @media (max-width: 425px) {
            position: absolute;
            right: 15px;
            height: 50px;
            width: 50px;
        }
        @media (max-width: 375px) {
            display: none;
        }
    }

    @keyframes loading-progress {
        0% {
          transform: translateX(0);
        }
        50%,
        100% {
          transform: translateX(100px);
        }
    }
}