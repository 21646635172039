.contact-banner {
    width: 100%;
    background-color: var(--light-bg-color);
    height: 100%;
    border-radius: 30px;
    padding: 34px 42px;
    @media (max-width: 425px) {
        padding: 20px;
    }

    .title {
        color: var(--text-black);
        font-size: 1.5rem;
        margin-bottom: 2.5rem;
        @media (max-width: 425px) {
            font-size: 1.4rem;
        }
    }

    .contacts {
        display: flex;
        flex-direction: column;

        i {
            margin-right: 15px;
            font-size: 24px;
            width: 30px;
            @media (max-width: 425px) {
                font-size: 20px;
            }
        }

        .row {
            margin-bottom: 20px;
            color: var(--text-black);
            cursor: pointer;
            &:hover {
                > span {
                    text-decoration: underline;
                }
            }
            > span {
                font-size: 18px;
            }
        }
    }

    .form-wrapper {
        margin-top: 20%;
        @media (max-width: 425px) {
            margin-top: 10%;
        }

        hr {
            margin-top: 0;
            background: var(--text-black);
        }

        .form {
            min-height: 300px;
            background-color: var(--white);
            border-radius: 6px;
            width: 100%;
            padding: 0.5rem;
        }

        .btn {
            height: 42px;
            width: 160px;
            border: none;
            border-radius: 30px;
            margin-top: 20px;
            cursor: pointer;
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
            background-color: var(--primary) !important;
            color: white;
            @media (max-width: 425px) {
                height: 35px;
                width: 120px;
                font-size: 15px;
            }
        }
    }

    .danger {
        border-radius: 6px;
        border: 2px solid var(--orange);
    }
}