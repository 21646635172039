@import "../../../../index.scss";
.offers {
    &-heading {
        text-transform: uppercase;
        color: white;
        font-size: 52px;
        font-weight: 500;
        @media (max-width: 540px) {
            font-size: 20px;
        }
    }

    &-description {
        color: white;
        @media (max-width: 540px) {
            font-size: smaller;
        }
    }

    &-section-heading {
        font-size: 42px;
        font-weight: 500;
        color: var(--text-black);
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
        line-height: 1.1;
        @media (max-width: 540px) {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 30px;
        }
    }
}

.redirect-user {
    border-radius: 19px;
    border: 2px dashed var(--primary);
    background-color: var(--light-bg-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 240px;
    @media (max-width:768px){
        flex-wrap: wrap;
    }
}

.redirect-user-content {
    margin: 30px 64px;
    margin-right: 160px;
    width: 50%;
    word-break: break-word;
    @media (max-width: 2005px) {
        width: 45%;
    }
    @media (max-width: 1792px) {
        margin-right: 110px;
    }
    @media (max-width: 1663px) {
        width: 40%;
        margin-right: 100px;
    }
    @media (max-width: 1446px) {
        margin-right: 60px;
    }
    @media (max-width: 1063px) {
        width: 46%;
    }
    @media (max-width: 1010px) {
        margin-right: 50px;
    }
    @media (max-width: 991px) {
        order: 2;
        margin: 20px 22.5px 42px;
        width: 100%;
    }
}

.redirect-user-img {
    width: 330px;
    height: 181.1px;
    background-image: url("../../../../assets/images/offers/scape.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0px 8% 0px 0;
    @include directionSwitch (margin-right,margin-left,8%);
    background-position: center;
    @media (max-width: 1330px) {
       @include directionSwitch (margin-right,margin-left,5%);
        width: 260px;
    }
    @media (max-width: 991px) {
        width: 256.5px;
        height: 141.3px;
        width: 100%;
        order: 1;
        margin: 20px 22.5px 0;
    }
}

.emphasize-offer {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-black);
}

.button-base {
    height: 47px;
    border-radius: 30px;
    width: 127px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 320px) {
        font-size: smaller !important;
        width: 90px !important;
    }
}

.primary-button {
    border-radius: 30px;
    background-color: var(--primary);
    color: var(--white);
    text-transform: uppercase;
    cursor: pointer;
    font-size: medium;
    font-weight: 600;
    border: 2px solid var(--primary);
    @extend .button-base;
    @media (max-width: 1064px) {
        width: 100px;
    }
    &:hover {
        color: var(--white);
        box-shadow: 0 4px 12px 0 rgba(65, 185, 230, 0.32);
    }
}

.non-primary-button {
    border-radius: 30px;
    color: var(--primary);
    border: 2px solid var(--primary);
    background-color: var(--white);
    text-transform: uppercase;
    cursor: pointer;
    font-size: medium;
    font-weight: 600;
    @extend .button-base;
    @media (max-width: 1064px) {
        width: 100px;
    }
    &:hover {
        color: var(--primary);
        box-shadow: 0 4px 12px 0 rgba(65, 185, 230, 0.32);
    }
}

.flex-center-y {
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
        justify-content: center;
    }
}

.or {
    display: flex;
    margin: 0 16px;
}

.offer-sections-header {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    font-size: 28px;
    font-weight: 500;
    color: var(--gray);
    margin-top: 40px !important;
    margin-bottom: 60px;
    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 35px !important;
        margin-bottom: 20px !important;
        font-size: 18px;
        font-weight: 600;
    }
    .timer-wrapper{
        margin-top: 0px;
    }
}

.text-separator {
    &::before {
        content: "|";
        margin: 0 25px;
        font-size: 22px !important;
        font-weight: 100;
        color: #dbdbdb;
        font-size: smaller;
        @media (max-width: 768px) {
            display: none;
        }
    }
}

.offer-banner-text {
    margin-bottom: 25px;
    text-align: left;
    @media (max-width: 768px) {
        text-align: center;
    }
}
