@import "../../index.scss";
.staycae-nav {
  margin-bottom: 10px !important;
  margin-left: 50px;
  max-width: 49%;
  margin-top: 10px;
  z-index: 10000 !important;
  .navbar-item {
    font-size: 16px;
    font-weight: 400;
    color: var(--text-black);
    margin-right: 1%;
    transition: 0.3s;

    &:hover {
      background-color: white;
      color: var(--primary);
    }

    .navbar-link {
      &:hover {
        background-color: white;
        color: var(--primary);
      }
    }
    
  }

  .is-active {
    color: var(--primary) !important;
  }
  .offers-item {
    background-color: #138d5c;
    color: #fff;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    height: 42px;
    margin-top: 8px;
    @media (max-width: 1300px) {
      display: flex;
      align-items: center;
      background-color: #138d5c;
      padding: 10px !important;
      margin: 20px 0;
      max-width: 100px;
      transition: transform 0.3s ease;
    }
    img {
      margin-right: 5px;
    }
    &:hover {
      background-color: #00873d !important;  /* Darker green on hover */
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);  /* Stronger shadow on hover */
    }
  }
  .offers-item.is-active {
    background-color: #00873d !important;  /* Darker green on hover */
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);  /* Stronger shadow on hover */
  }
}

.button.login-header {
  width: 158px;
  height: 48px;
  padding: 15px 20px 14px 22px;
  border-radius: 24px;
  background-color: rgba(65, 185, 230, 0.12);
  font-size: 14px;
  color: var(--primary) !important;
  margin-right: 50px;
  font-weight: 400;
  border: none;
  font-size: 16px;
  border-radius: 24px;

  @media (max-width: 1200px) {
    margin-right: 0px;
  }
}

.navbar-item {
  .brand-logo {
    width: 198px;
    height: 48px;
    max-height: 10em;

    @media (max-width: 325px) {
      width: 158px;
      height: 48px;
    }
  }

  .profile-btn {
    cursor: pointer;

    .avatar-img {
      max-height: 2.75rem;
    }
  }
}

.navbar-brand {
  margin-left: 45px;
  align-items: center !important;

  @media (max-width: 425px) {
    margin-left: 0;
  }
}

.img-btn {
  border-color: transparent;
  background-color: transparent;
  outline: none;
  display: flex;
  align-items: center;
  color: var(--text-gray);
  cursor: pointer;

  .user-avatar {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 24px;
    max-height: 100%;
    @include directionSwitch(margin-right,margin-left,5px);
  }

  i {
    font-size: 14px;
  }
}

@media (max-width: 1180px) {
  .navbar-link:not(.is-arrowless) {
    padding-right: 0.5em !important;
  }
}

.dropdown-link-item {
  display: flex;
  align-items: center;

  .d-icon {
    height: 16px;
    width: 16px;
  }

  span {
    font-size: 16px;
    font-weight: normal;
    color: var(--gray);
    @include directionSwitch(margin-left,margin-right,10px);
  }
}

.logout {
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
}

.responsive-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow-y: scroll;
  background-color: var(--white);

  .responsive-header-wrapper {
    margin: 0 38px;
    margin-top: 77px;
  }

  .close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    font-size: 20px;
    -webkit-text-stroke: 1px white;
  }

  .button {
    width: 100%;
    border-radius: 0px !important;
  }

  .navbar-flex {
    flex-direction: column;
  }

  .navbar-item {
    margin-bottom: 32px;
    margin-right: 0;
    padding-right: 0;
    padding-left: 0;
  }

  .text-primary {
    color: var(--primary);

    &:hover {
      text-decoration: underline;
    }
  }

  .resp-lang-menu {
    border-top: 2px solid var(--border-gray);
    border-bottom: 2px solid var(--border-gray);
    margin-bottom: 32px;
    padding: 0;
    padding-top: 18px;
    padding-bottom: 18px;
    position: relative;

    &:hover i {
      color: var(--text-gray);
    }

    .down-arrow {
      color: var(--border-gray);
    }

    img {
      max-height: none;
      height: 40px;
      width: 40px;
    }

    > .navbar-item {
      margin-bottom: 0;
    }

    .navbar-item {
      margin-right: 0;
    }

    .navbar-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border: none;
      background-color: var(--white);
      font-size: 16px;
      font-weight: 400;
      color: var(--text-black);
    }

    .navbar-dropdown {
      border: 2px solid var(--border-gray);
      border-radius: 6px;
      margin-top: 10px;
      padding-top: 16px;
      position: absolute;
      width: 100%;
      background-color: var(--white);
      z-index: 100;

      .navbar-item {
        display: flex;
        align-items: center;
        padding-left: 0.75rem;
        margin-bottom: 16px;
      }
      #sub-menu{
        .navbar-item{
          @include directionSwitch(padding-left, padding-right,0.75rem);
        }
      }
    }
  }

  .profile {
    margin-bottom: 38px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    
    .pfp {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      margin-right: 18px;
    }

    &-content {
      flex: 1;
    }

    &-content-options {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }

    &-optn {
      font-size: 16px;
      color: var(--gray);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .logout-optn {
      font-size: 16px;
      color: #ff5353;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.navbar-burger {
  display: none !important;

  @media (max-width: 1300px) {
    cursor: pointer;
    display: flex !important;
    justify-content: center;
  }
}

.navbar-menu {
  @media (max-width: 1300px) {
    display: none !important;
  }
}

.navbar-brand {
  @media (max-width: 1300px) {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
  }
}
.lang-menu {
  a:hover {
    background-color: white !important;
  }
}
.navbar-link.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, a.navbar-item.is-active, a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover{
  background-color: transparent !important;
}
.navbar-dropdown a.navbar-item{
  @include valueSwitch(padding-left,1rem,2rem);
}
.navbar-item{
  .dropdown-menu {
    @include valueSwitch(right,unset, -125px !important);
  }
}