
.remove {
    transition: all 10ms ease 0s !important;
}

.home-areas-banner {
    .slick-dots{
        .slick-active {
            margin-right: 35px;
        }

    }
}