.wishlist-loader {
    @media screen and (min-width: 769px) {
        &.columns:not(.is-desktop) {
            display: flex;
            justify-content: space-between;
        }
    }

    .wishlist-contents {
        display: flex;
        justify-content: space-between;
        .left-pane {
            width: 60%;
            @media (max-width: 1024px) {
                width: 50%;
            }
            @media (max-width: 425px) {
                width: 100%;
            }
        }
        @media (max-width: 425px) {
            flex-direction: column;
        }
    }

    .wishlist-image {
        background-color: #f2f2f2;
        width: 260px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 70%;
            height: inherit;
            border-radius: 30px;
            background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
            animation: type-1 2s infinite linear;
        }
        @media (max-width: 768px) {
            margin-right: 30px;
        }
        @media (max-width: 767px) {
            margin-right: 0;
            height: 200px;
            width: 100%;
        }
    }

    .wishlist-name {
        height: 30px;
        background-color: #f2f2f2;
        width: 100%;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: inherit;
            background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
            animation: type-1 2s infinite linear;
        }
    }

    .amenities-wrap {
        margin-top: 20px;
        > .title {
            height: 20px;
            background-color: #f2f2f2;
            margin-bottom: 10px !important;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: inherit;
                background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
                animation: type-1 2s infinite linear;
            }
        }
        > .content {
            height: 15px;
            background-color: #f2f2f2;
            display: block;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 60%;
                height: inherit;
                background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
                animation: type-1 2s infinite linear;
            }
        }
    }

    .wishlist-price {
        height: 25px;
        width: 110px;
        background-color: #f2f2f2;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: inherit;
            background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
            animation: type-1 2s infinite linear;
        }
        @media (max-width: 425px) {
            margin: 0;
        }
    }

    .rating-wrap {
        height: 25px;
        width: 130px;
        background-color: #f2f2f2;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 60%;
            height: inherit;
            background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
            animation: type-1 2s infinite linear;
        }
    }

    .staycae-btn {
        width: 100%;
        border-radius: 30px;
        height: 50px;
        margin-top: 20px;
        pointer-events: none;
        @media (max-width: 425px) {
            margin: 0;
            margin-top: 20px;
        }
    }

    .wishlist-booking-container-loader {
        @media (max-width: 425px) {
            margin-top: 20px;
        }
    }
}

@mixin progress-loading($name, $width) {
    @keyframes #{$name} {
        0% {
          transform: translateX(0);
        }
        50%,
        100% {
          transform: translateX($width);
        }
    }
}

@include progress-loading(type-1, 50px);