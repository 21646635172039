@import "../../styles/_functions.scss";
@import "../../styles/_common.scss";
@import "../../index.scss";

#navbarBasicExample {
  justify-content: flex-end;
}

.navbar-flex {
  display: flex;
  justify-content: flex-end;
}

.lang-menu {
  img {
    width: 20px;
    height: 20px;
    border-radius: 75%;
    margin-inline-end: 1rem;
  }
}

.navbar-link:not(.is-arrowless)::after {
  display: none !important;
}

.container.is-fluid.home-searchbar {
  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.section.home-search-bg {
  background-image: image("home.png");
  background-position: top center;
  background-repeat: no-repeat;
  height: 800px;
  margin-bottom: 10px;
  transition: all 500ms ease;
  border-radius: 50px;

  @media (max-width: 768px) {
    min-height: 0vh;
    height: auto;
    background-size: cover;
    border-radius: 50px 50px 0 0;
  }

  @media (max-width: 325px) {
    padding: 3rem 0.2rem;
  }
  @media (min-width: 1100px) {
    padding-top: 8rem;
  }

  .newtitle {
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: normal;
    line-height: 0.75em;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 52px;

    @media (max-width: 990px) {
      font-size: 45px;
    }

    @media (max-width: 768px) {
      font-size: 1.8em;
    }

    @media (max-width: 510px) {
      font-size: 1.5em;
      line-height: 1;
      margin-bottom: 10px;
    }

    @media (max-width: 320px) {
      font-size: 1em;
    }

    .white {
      color: var(--white);
    }

    .blue {
      color: var(--blue);
    }

    &:last-of-type {
      margin-bottom: 1em;
    }
  }
}

// home-areas-banner
.home-areas-left {
  font-size: 24px;
  color: black;
  font-weight: 500;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 19px;
  }

  .highlights {
    font-size: 42px;
    font-weight: 500;
    color: black;

    @media (max-width: 768px) {
      font-size: 32px;
    }

    @media (max-width: 550px) {
      font-size: 30px;
    }
  }
}

.staycae-btn.book-now-areas {
  font-weight: 500;
  width: 145px;
  height: 55px;
  /* margin: 24px 652px 0 0; */
  /* padding: 19px 35px; */
  border-radius: 30px;
  box-shadow: 0 9px 14px 0 var(--primary) 24;
  background-color: var(--primary);
  font-size: 18px;
}

.dropdown-content.areas-dropdown {
  text-align: left;
  margin-top: 10px;

  div {
    padding: 5px 15px;

    &:hover {
      background-color: #a0e6bd80;
    }
  }
}

.home-areas-banner {
  position: relative;

  @media (max-width: 1024px) {
    background-color: var(--light-bg-color);
  }

  .slick-list {
    margin-bottom: 35px;
  }

  .center {
    width: 55vw;
    position: absolute;
    @include directionSwitch(right, left, -12%);
    top: -30%;
    max-width: 800px;

    @media (max-width: 990px) {
      width: 55vw;
      position: relative;
      @include directionSwitch(right, left, -5%);
      top: -10%;
      max-width: 800px;
      min-width: 777px;
      padding-bottom: 36px;
    }

    @media (max-width: 650px) {
      min-width: 658px;
    }

    @media (max-width: 425px) {
      padding: 10px 20px;
    }
  }

  .content-areas-banner {
    background-color: var(--light-bg-color);
    border-radius: 0px 0px 0px 50px;
    padding: 2rem 5rem 3.5rem;

    @media (max-width: 768px) {
      padding: 40px;
    }

    .brand-quote {
      @media (max-width: 990px) and (min-width: 768px) {
        width: 100%;
      }

      @media (max-width: 768px) {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .spacer-div {
    background: white;

    @media screen and (max-width: 1380px) and (min-width: 768px) {
      display: none;
    }
  }

  .slick-dots {
    @include valueSwitch(width, auto, 90%);
    width: auto;
    float: left;
    margin-left: 20px;

    @media only screen and (max-width: 990px) {
      @include valueSwitch(width, 100%, 90%);
      top: 100%;
      margin-top: -20px;
    }

    @media only screen and (max-width: 768px) {
      display: none !important;
    }

    .slick-active {
      button {
        @extend .dot-active;
        border-radius: 10px;
        padding: 0px;

        &:hover,
        &:focus {
          box-shadow: none;
        }
      }

      button::before {
        content: "";
      }
    }

    li:not(.slick-active) {
      button {
        @extend .dot-button;
        padding: 0px;

        &:hover,
        &:focus {
          box-shadow: none;
        }
      }

      button::before {
        content: "";
      }
    }
  }
}

.clipped-carousel {
  position: absolute;
  width: 67%;
  margin-top: -110px;

  @media (max-width: 900px) {
    position: relative;
    width: 100%;
    margin-top: 10px;

    .rec-slider {
      margin: 0px;
    }
  }

  @media screen and (max-width: 1350px) and (min-width: 1200px) {
    width: 35%;
  }

  @media screen and (max-width: 1040px) and (min-width: 900px) {
    width: 48%;
  }

  @media screen and (max-width: 1200px) and (min-width: 1040px) {
    width: 38%;
  }

  @media screen and (max-width: 900px) and (min-width: 768px) {
    width: 136%;
    margin-top: -90px;
  }

  .rec-arrow {
    display: none;
  }

  .rec-dot {
    @extend .dot-button;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  .rec-dot_active {
    @extend .dot-active;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  .rec-pagination {
    margin-left: -85px;
  }
}

.all-title {
  font-size: 42px;
  font-weight: 500;

  @media (max-width: 660px) {
    font-size: 1.6em;
    padding: 30px;
  }
}
.home-areas-banner {
  .slick-slide {
    position: relative;
  }
}
.attraction-title-holder {
  border-radius: 0px 0 30px 30px;
  position: absolute;
  bottom: 7px;
  left: -2px;
  /* margin-left: 10px; */
  width: 87%;
  height: 13%;
  color: white;
  margin-left: 2px;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7));

  span {
    margin-left: 40px;
    font-size: 20px;
    font-weight: 500;
  }
}

.areas {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  img {
    transition: 0.3s;
    background-color: transparent;
    border-radius: 30px;
    width: 87%;
    height: 380px;
    max-width: 390px;
    object-fit: cover;

    &:hover {
      box-shadow: 3px 3px 5px 6px var(--text-gray);
      border-radius: 30px;
    }
  }
}

.calendar-dropdown {
  .dropdown-menu {
    @media (max-width: 375px) {
      margin-left: -15px;
    }

    @media (max-width: 325px) {
      margin-left: -20px;
    }
  }
}

.increase-distance {
  padding: 11rem 1.5rem 9rem !important;
}

.home-search-bg {
  @media screen and (max-width: 425px) {
    padding: 3rem 0.5rem !important;
  }
}

.hg-desc {
  color: var(--gray);
}
.areas,
.slick-slide,
.module {
  :focus {
    outline: none;
  }
}
.slick-slide {
  :focus {
    outline: none;
  }
}

.rec-item-wrapper {
  :focus {
    outline: none;
  }
}
