:root {
  --tab-title: 25px;
}

.tabs__box {
  margin: 0 -20px 20px -20px;
}

.tabs__box:after {
  content: "";
  margin: -4px 20px 0;
  display: block;
  border-bottom: 1px solid #dddddd;
}

#accordian_parent {
  .tabs__box {
    margin: 0 -35px 8px -10px;
  }

  .tabs__tab {
    font-size: 20px;
  }
  .tabs__tab:after {
    content: "";
    display: block;
    padding-top: 13px;
    margin-bottom: -1px;
    width: 102%;
  }
}

.tabs__tab {
  text-decoration: none;
  display: inline-block;
  border: none;
  outline: none;
  font-size: var(--tab-title);
  font-weight: 500;
  background: transparent;
  color: rgba(0, 0, 0, 0.5);
  margin: 4px;
  cursor: pointer;
  width: 50%
}

.tabs__tab-active {
  color: var(--primary);
  font-size: var(--tab-title);
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.tabs__tab:after {
  content: "";
  display: block;
  padding-top: 22px;
  margin-bottom: -16px;
  border-bottom: 3px solid lightgrey;
  width: 104%;
}

.tabs__tab-active:after {
  content: "";
  display: block;
  padding-top: 22px;
  margin-bottom: -16px;
  border-bottom: 4px solid var(--primary);
  width: 101.3%;
}