@import "../../../../index.scss";
.tabs-section {
    display: flex;
    .tab-item {
        font-size: 14px;
        font-weight: 400;
        white-space: normal;
        padding: 1rem 2rem 0 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        i {
            display: none;
        }
    }

    .selected {
        border-bottom: 4px solid white;
    }
    @media only screen and (min-width: 1024px) {
        display: flex;
        flex-direction: column;
        background-color: var(--light-bg-color);
        padding-top: 3.25rem;
        padding-bottom: 2.25rem;
        max-width: 24.375rem;
        border-radius: 30px;
        .tab-item {
            padding: 0;
            margin-bottom: 3.25rem;
            font-size: 18px;
            i {
                display: inline-block;
                @include directionSwitch (margin-left,margin-right,3.313rem);
                @include directionSwitch (margin-right,margin-left,1.25rem);
                font-size: 20px;
            }
            span {
                font-size: 18px;
                font-weight: 500;
            }
        }
        .selected {
            border-bottom: initial;
            color: var(--primary);
            border-left: 4px solid var(--primary);
            padding: 10px 0px;
        }
    }
    @media (max-width: 1024px) {
        .tab-item i {
            margin-left: 2.313rem;
        }
    }
    a {
        text-decoration: none;
        color: var(--text-black);
    }
}
