.carousel-track {
    margin-bottom: 65px;
}

.carousel-item {
    display: flex;
    justify-content: center;
}

.hide-navigation {
    display: none;
}

.dot-button {
    width: 14px;
    height: 6px;
    border-radius: 3px;
    background-color: var(--primary);
    opacity: 0.4;
    box-shadow: none;
}

.dot-active {
    background: var(--primary);
    width: 50px;
    height: 6px;
    border-radius: 3px;
    border: none;
    opacity: 1;
    box-shadow: none;
}

.align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
}

.align-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    align-items: center;
}

.fw-500 {
    font-weight: 500;
}

.container-main {
    max-width: 95%;
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto;
    border-radius: 30px 30px 0px 0px;
}
.slick-next, .slick-prev{
    z-index: 1;
  }
  .react-tel-input .country-list{
      max-height: 200px !important;
  }
  .rdrCalendarWrapper{
      @media (max-width:600px){
          width: 100%;
      }
      .rdrMonth{
        @media (max-width:600px){
            width: 100%;
        }
      }
  }