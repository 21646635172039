.change-pwd {
    padding-bottom: 4rem;
    .save-btn {
        width: 96%;
        cursor: pointer;
        text-transform: uppercase;
        text-align: center;
        border-radius: 30px;
        background-color: var(--primary);
        color: var(--white);
        font-size: 18px;
        font-weight: 500;
        border-color: transparent;
        padding: 1.188rem 0;
    }
    .text-field{
        max-width: 27.375rem;
        max-height: 3.5rem;
        margin-bottom: 3rem !important;
        @media(max-width: 1020px) {
            margin-bottom: 3.8rem !important;
        }
    }
  
}
