.pagination {
  align-items: flex-start;
  display: flex;
  justify-content: center !important;
  text-align: center;

  .active {
    background-color: #4ab9e6 !important;
    color: white !important;
    a {
      color: white !important;
      cursor: default;
    }
  }
  li {
    color: #0d6efd;
    text-decoration: none;
    background-color: #fff;
    padding: 5px 10px;
    border: 1px solid #dee2e6;
    font-size: 12px;
    margin-top: 15px;
  }
  .previous,
  .next {
    padding: 5px;
  }
}
.table-card {
  white-space: nowrap;
  padding-bottom: 20px;
  border: 1px solid #dbdbdb;
  overflow: hidden;
  .table-card-header {
    border-bottom: 1px solid #dbdbdb;
    align-items: center;
  }
}
.table-card-header {
  h4 {
    text-transform: unset;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  select {
    margin-right: 20px;
    background-color: transparent;
    border-radius: 5px;
    height: 35px;
    cursor: pointer;
    border-color: #cccccc;
  }
}
.table-sort-icon {
  width: 20px;
  font-size: 10px;
  cursor: pointer;
}
