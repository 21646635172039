.staycae-btn {
    font-size: 24px;
    font-weight: 500;
    box-shadow: 0 9px 14px 0 rgba(19, 141, 92, 0.14);
    transition: 0.5s;
    border-radius: 30px;

    &:hover {
        box-shadow: 0 4px 12px 0 rgba(65, 185, 230, 0.32) !important;
    }
}

.staycae-btn.is-secondary {
    color: var(--primary);
    transition: 0.1s;
    border: solid 2px var(--primary);
    transition: 0.5s;

    &:hover {
        color: var(--primary);
        border: solid 2px var(--primary);
        box-shadow: 0 4px 12px 0 rgba(65, 185, 230, 0.32) !important;

    }
}