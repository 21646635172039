.report-options {
    border-radius: 30px;
    border: 2px solid var(--border-light-gray);
    padding: 25px;
    background-color: white;

    .section-title {
        font-size: 22px;
        font-weight: 500;
        text-transform: capitalize;
        color: var(--gray);
        @media (max-width: 425px) {
            font-size: 18px;
        }
    }

    .dropdown {
        width: 100%;
        .dropdown-trigger,
        .button {
            width: inherit;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            height: auto;
            align-items: flex-start;
            > span {
                margin-bottom: 10px;
                margin-right: calc(-0.5em - 6px) !important;
            }
        }
    }
    
    .dropdown.is-active .dropdown-menu, 
    .dropdown.is-hoverable:hover .dropdown-menu {
        display: block;
        width: 100%;
    }

    .dropdown-content {
        max-height: 200px;
        overflow-y: scroll;
    }

    .unit-list-item {
        cursor: pointer;
        &:hover {
            background-color: #e8e8e865;
        }
    }

    .label {
        color: var(--text-gray);
        font-size: 1em;
        font-weight: 400;
    }

    .select-all {
        padding: 0 16px !important;
    }

    .date-range {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1rem;

        .to {
            margin-left: 0;
        }

        > span {
            margin-bottom: 5px;
            color: var(--text-gray);
        }

        > div {
            margin-bottom: 1rem;
        }

        input {
            width: 100%;
        }
    }

    .download-btn  {
        cursor: pointer;
        border: none;
        height: 40px;
        padding: 0 10px;
        outline: transparent;
        font-size: 15px;
        border-radius: 6px;
        width: 100%;
        background-color: var(--primary);
        color: var(--white);
        border-color: var(--primary);
        &:hover {
            color: var(--white);
            box-shadow: 0 0 3px 0 #41bae69c;
            border-color: var(--primary);
        }
        @media (max-width: 1280px) {
            margin-bottom: 10px;
        }
    }

    .multiSelectOptions.dropdown .button {
        min-width: auto;
    }
    
}
