.modal {
    .background-banner {
        background-color: rgba(83, 87, 94, 0.68);
    }
    .sub-title{
        margin-bottom: 10px;
        font-size: 14px;
        color: var(--gray);
    }
    .modal-window {
        max-width: 35.125rem;
        max-height: 40rem;
        border-radius: 30px;
        box-shadow: 0 31px 80px 0 rgba(0, 0, 0, 0.19);
        .less-padding{
            padding: 25px 1rem !important;
        }
        .modal-header {
            
            padding: 3.875rem 3.25rem;
            background-color: white;
            border-color: white;
            .heading-center{
                text-align: center;
            }
            .card-title {
                font-size: 24px;
                font-weight: 500;
                text-transform: uppercase;
            }
            .close-btn {
                cursor: pointer;
                background-color: transparent;
                border-color: transparent;
                img {
                    max-width: 1.313rem;
                    max-height: 1.313rem;
                    object-fit: contain;
                }
            }
        }
        .mod-content {
            margin: 0;
            padding: 0 3.25rem;
        }

        .modal-footer {
            background-color: white;
            border-color: white;
            justify-content: center;
            padding: 2rem 0 4rem;
            
        }
    }
}
