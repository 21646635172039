.layout {
    user-select: none;
    &-banner {
        margin: 0 33px;
        border-radius: 2rem;
        overflow: hidden;
        @media (max-width: 540px) {
            margin: 0;
            border-radius: 2rem 2rem 0 0;
        }
    }

    &-banner-mask {
        background: #5076b0;
        min-height: 230px;
    }

    &-banner-img {
        background-image: url("../../../assets/images/pattern.jpg");
       background-repeat: repeat;
    }

    &-grid {
        margin: 10px 17% 20px;
        text-align: center;
        @media (max-width: 540px) {
            margin: 40px 20px;
        }
    }
}

.carousel-wrapper,
.monthly-deal-wrapper {
    .slick-dots {
        position: relative !important;
        bottom: 0 !important;
        margin-top: 35px;
        @media (max-width: 540px) {
            margin-top: 32px;
        }

        .slick-active {
            background-color: var(--primary);
            width: 50px !important;
            height: 6px !important;
            border-radius: 3px;
            opacity: 1;
        }

        li {
            width: 15px !important;
            height: 6px !important;
            border-radius: 3px;
            background-color: var(--primary);
            opacity: 0.4;
            transition: all 250ms ease;
        }

        li button:before,
        li.slick-active button:before {
            display: none !important;
        }
    }

    .carousel-arrow {
        background-color: var(--white);
        border: none;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        box-shadow: 0 19px 33px 0 rgba(0, 0, 0, 0.3);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1025px) {
            display: none;
        }
    }

    .slick-arrow.slick-prev {
        left: 5%;
    }
    .slick-arrow.slick-next {
        right: 5%;
    }

    .carousel-arrow-left img {
        transform: rotate(180deg);
    }

    .carousel-arrow-left:hover {
        transition: all 250ms ease;
        transform: translateX(-5px);
    }
    .carousel-arrow-right:hover {
        transition: all 250ms ease;
        transform: translateX(5px);
    }

}

.carousel-wrapper .slick-list {
    flex-direction: column;
}

.flex-align {
    display: flex;
    align-items: center;
}
