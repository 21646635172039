.remove-wrapper {
    width: 40px;
    height: 40px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;

    @media (max-width: 768px) {
        left: 20px;
    }

}

.icon-wishlist {
    cursor: pointer;
    width: 22px;
    height: 21px;
    opacity: .5;

    background: url('../../../../../../assets/icons/search/heart-empty.svg');

    &:hover {
        opacity: 1;
    }

    &.in-wishlist {
        opacity: 1;
        animation-name: wishlist-ani;
        animation-duration: 1000ms;
        filter: invert(57%) sepia(69%) saturate(4800%) hue-rotate(345deg) brightness(96%) contrast(97%);
    }
}

@keyframes wishlist-ani {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(.5);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

path {
    transition: fill 0.3s;
}

.active {
    path {
        fill: #f05c25;
        stroke: #f05c25
    }

    svg {
        animation: beat cubic-bezier(0.04, 0.4, 0.5, 0.95) 1.2s forwards 1;
    }
}

@keyframes beat {
    30% {
        transform: scale(1.4);
    }

    50% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1);
    }
}