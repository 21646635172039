.multiSelectOptions {
    &.dropdown {
        width: 100%;
        .dropdown-trigger,
        .button {
            width: inherit;
            justify-content: flex-start;
            min-height: 42px;
            border-color: #ccc;
        }
    }
    
    &.dropdown.is-active .dropdown-menu, 
    &.dropdown.is-hoverable:hover .dropdown-menu {
        display: block;
        width: 100%;
        background-color: white;
    }

    .dropdown-content {
        max-height: 200px;
        overflow-y: scroll;
        border-radius: 0;
        box-shadow: none;
    }

    .unit-list-item {
        cursor: pointer;
        &:hover {
            background-color: #e8e8e865;
        }
    }

    .tag {
        font-size: 12.5px !important;
        background-color:var(--primary);
        color: white !important;
        &:hover i {
            background-color: rgba(37, 37, 88, 0.507);
            padding: 2px 4px;
            border-radius: 50%;
        }
    }

    .icon {
        position: absolute;
        right: 15px;
    }

    .dropdown-menu {
        border-radius: 4px;
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1);
    }

    .control {
        width: 95%;
        margin: 0 auto;
    }

    .input:focus {
        border-color: var(--primary);
        box-shadow: 0 0 0 0.125em #41bae681;
    }

    .dropdown-item {
        padding: 0.375rem 1.5rem;
    }
}

.dashboard-main .multiselect-units {
    @media (max-width: 1024px) {
        width: 90%;
        margin-top: 20px;
    }
}

.units-dropdown {
    .selected-unit {
        background-color: var(--primary);
        color: var(--white);
        &:hover {
            background-color: var(--primary) !important;
            color: var(--white) !important;
        }
    }
}
