.non-member-checkout {
    color: var(--text-black);

    .highlight-text {
        font-size: 18px;
    }

    .mod-content {
        padding: 2.25rem !important;
        text-align: center;
    }

    .strike-through {
        color: var(--gray);
    }

    .member-rate {
        font-weight: 500;
    }

    .grab-offer {
        font-size: 14.5px;
        color: var(--text-gray);
    }

    .book-btn {
        background-color: var(--primary);
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        border-radius: 30px;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 15px 25px;
        font-size: 18px;

        &:hover {
            box-shadow: 0 4px 12px 0 rgba(65, 185, 230, 0.32);
        }
    }

    .guest-btn {
        background-color: var(--light-bg-color);
        border-radius: 30px;
        padding: 15px 25px;
        color: var(--primary);
        font-size: 17px;
        font-weight: 500;
        border-color: transparent;
        cursor: pointer;
    }
}

.confirm-cancel {
    padding: 11px 50px !important;
}
.confirm-cancel.is-disabled{
    color:white;
}