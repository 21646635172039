@import "../../../../index.scss";
.refer-and-earn {
  .offer-code-wrapper {
    padding: 6px;
    flex-wrap: wrap;
  }

  .offer-code {
    word-break: break-all;
  }

  .input {
    border-left: none;
    border-right: none;
    border-top: none;
    box-shadow: none;
    border-radius: inherit;
    font-size: 14px;
  }

  .input:active,
  .input:focus {
    border-color: var(--primary);
    box-shadow: none;
  }

  .tag {
    font-size: 12.5px !important;
    background-color: var(--primary);
    color: white !important;
    cursor: pointer;
    justify-content: space-between;
    &:hover i {
      background-color: rgba(37, 37, 88, 0.507);
      padding: 2px 4px;
      border-radius: 50%;
    }
  }

  .tagarea {
    display: flex;
    flex-wrap: wrap;
    max-height: 40em;
    min-height: 5em;
    background-color: white;
    border: 1px solid var(--border-light-gray);
    border-radius: 4px;
    color: #363636;
    position: relative;
  }

  .noresult-static {
    span {
      padding: 3px;
      background-color: var(--border-light-gray);
      border-radius: 4px;
    }
  }

  .send-email {
    width: 120px;
    height: 42px;
    border-radius: 24px;
    background-color: var(--primary);
    color: white;
    font-weight: 500;
    border: none;
    cursor: pointer;
  }

  .email-error,
  .email-error:active,
  .email-error:focus {
    border-color: red;
    box-shadow: none;
  }

  .has-text-danger {
    font-size: 13px;
  }

  .modal-card.modal-window {
    max-width: 42.125rem;
  }

  .modal-card-body {
    position: relative;
  }

  .close-btn {
    position: absolute;
    @include directionSwitch(right, left, 25px);
    top: 20px;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    img {
      max-width: 1rem;
      max-height: 1rem;
      object-fit: contain;
    }
  }

  .rtl-offer-code-wrapper {
    flex-direction: row-reverse;
  }
  
  .rtl-no-result {
    flex-direction: row-reverse;
    .noresult-info {
      margin-right: auto;
      margin-left: 20px;
    }
  }
}
