.login-info {
    position: relative;
    color: rgba(6, 7, 9, 0.5);
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin: 1.5em 0em;
}

.social-media-btn-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }

    .column {
        @media only screen and (max-width: 768px) {
            margin: 0 0 2.8em 0;
        }
    }

    .column-left {
        margin-right: 2em;
        @media only screen and (max-width: 768px) {
            margin-right: 0;
        }
    }

    .social-media-btn {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
        border-radius: 24px;
        background-color: #f6f6f6;
        height: 52px;
        border-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;
    }

    .fb-btn {
        @extend .social-media-btn;
        color: var(--gray);
        .fab {
            background-color: #3a589b;
            color: white;
            height: 21px;
            width: 21px;
            padding: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    
    .gmail-btn {
        @extend .social-media-btn;
        color: var(--gray);
        .gmail-icon {
            font-size: 21px;
            margin-right: 10px;
            background: conic-gradient(
                    from -45deg,
                    #ea4335 110deg,
                    #4285f4 90deg 180deg,
                    #34a853 180deg 270deg,
                    #fbbc05 270deg
                )
                73% 55%/150% 150% no-repeat;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
        }
    }
}
