    .single-star-outline {
    height: 36px;
    width: 31px;
    max-width: fit-content;
  }
  
  .single-star-fill {
    position: relative;
    display: inline-block;
    height: 36px; 
    background-color: #f4b914;
  }
  
  .single-star-container {
    height: 36px; 
    width: 31px;
    display: inline-block;
  }