@import "../../../../index.scss";
.box.search-holder {
    display: flex;
    padding: 1.25rem;
    justify-content: space-around;
    width: 75vw;
    margin: auto;
    border-radius: 100px;
    margin-top: 4em;
    align-items: center;
    padding-left: 42px;
    padding-right: 24px;
    max-width: 1110px;
    padding-top: 35px;
    padding-bottom: 35px;


    @media (max-width:1300px) {
        width: 87vw;
    }

    @media (max-width:990px) {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    @media (max-width:768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        border-radius: 30px;
        position: relative;
        .dropdown-subtitle{
            font-size: 10px !important;
        }
       
    }

    
    .mobile-home-filter {
        margin-top: 20px;
        width: 95%;

        .search-dropdown {
            width: 100%;
        }

        .guest-menu-mob {
            width: 100%;

            .guest-dd {

                width: 100%;
            }
        }

        .search-bar {
            width: 100%;

            .dropdown-button {
                @media (max-width: 425px) {
                    padding-left: 10px;
                    padding-right: 10px;
                }

            }
        }

        .dropdown-trigger,
        .dropdown-button {
            width: 100% !important;
        }

        .MobileSearch-dates {
            margin-top: 20px;
            margin-bottom: 20px;
            justify-content: space-between;

            &>div {
                width: 48%
            }

            .search-bar {
                max-width: 100%;
            }
        }

        .MobileSearch-searchbtn {
            width: 180px;
        }
    }

    .dropdown-button {
        width: 170px !important;
    }

    .autocomplete-btn {

        @media screen and (min-width:1110px) {

            width: 19vw !important;
            max-width: 330px;
        }

        @media screen and (max-width:1110px) {
            width: 100% !important;
        }

        input {
            font-weight: 500;
            color: black;
            padding-top: 15px;
            padding-bottom: 15px;
            text-overflow: ellipsis;
        }
    }

    .guest-dd {
        height: 70px;
    }

    .dropdown-subtitle {
        color: var(--text-gray);
        display: flex;
        align-items: center;
        word-spacing: 3px;
        font-size: 13px;
        width: 100%;
    }

    .dropdown-button {
        width: 170px !important;
    }

    .autocomplete-btn {

        @media screen and (min-width:1110px) {
            width: 19vw !important;
            max-width: 330px;
        }


        input {
            font-weight: 500;
        }
    }

    .guest-dd {
        height: 70px;
    }

    .dropdown-subtitle {
        color: var(--text-gray);
        display: flex;
        align-items: center;
        word-spacing: 3px;
        font-size: 13px;
    }



    .search-btn {
        text-transform: uppercase;
        border-radius: 30px;
        width: 132px;
        height: 54px;
        border-radius: 30px;
        box-shadow: 0 9px 14px 0 var(--primary)24;
        background-color: var(--primary);

        @media only screen and (max-width: 768px) and (min-width: 600px) {
            position: absolute;
            right: 30px;
            margin-top: 15px;
        }

        @media (max-width:600px) {
            margin-top: 15px;
        }
    }

    .dropdown-title {
        @media (max-width: 325px) {
            margin-right: 5px;
        }
    }

    .dropdown-trigger {
        .button:focus {
            box-shadow: none !important;
        }
    }
}

.search-autocomplete {
    @media (min-width: 1110px) {
        width: 13.5vw !important;
    }

}


#closeicon {
    color: #fff;
    position: absolute;
    @include directionSwitch(right, left, 7px);
    width: 21px;
    height: 21px;
  :hover {
        color: #cacaca !important;
    }

}

.fa-times-circle {
    font-size: 17px;
   color: #cccaca;

}