.toggle-wrapper-owner {
  width: 12vw;
  border-radius: 25px;
  background: #fff;
  border: solid 1px var(--border-gray);

  @media (max-width: 1600px) {
    width: 11vw;
  }
  @media (max-width: 1100px) {
    width: 23vw;
  }

  @media(max-width:550px) {
    width: 45vw;
}

@media(max-width:400px) {
    width: 55vw
}
}

.toggle-wrapper-owner header {
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 20px;
}

.toggle-wrapper-owner nav {
  position: relative;
  height: 38px;
  display: flex;
  align-items: center;
}

.toggle-wrapper-owner nav label {
  display: block;
  height: 100%;
  width: 100%;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  color: var(--text-gray);
  font-size: 13px;
  border-radius: 5px;
  margin: 0 5px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  svg {
    margin-right: 10px;
  }

  path {
    fill: var(--text-gray);
  }

  img {
    margin-right: 10px;
  }
}

#home:checked ~ nav label.home,
#blog:checked ~ nav label.blog,
#code:checked ~ nav label.code,
#help:checked ~ nav label.help,
#about:checked ~ nav label.about {
  color: #fff;

  path {
    fill: white !important;
  }
}

nav label i {
  padding-right: 7px;
}

nav .slider {
  position: absolute;
  width: 52%;
  left: -3px;
  z-index: 0;
  border-radius: 30px;
  background: var(--primary);
  transition: all 0.3s ease;
  margin-left: 5px;
  height: 90%;
}

input[type="radio"] {
  display: none;
}

#blog:checked ~ nav .slider {
  left: 44.5%;
}

#code:checked ~ nav .slider {
  left: 40%;
}

#help:checked ~ nav .slider {
  left: 60%;
}

#about:checked ~ nav .slider {
  left: 80%;
}

section .content {
  display: none;
  background: #fff;
}

#home:checked ~ section .content-1,
#blog:checked ~ section .content-2,
#code:checked ~ section .content-3,
#help:checked ~ section .content-4,
#about:checked ~ section .content-5 {
  display: block;
}

section .content .title {
  font-size: 21px;
  font-weight: 500;
  margin: 30px 0 10px 0;
}

section .content p {
  text-align: justify;
}
