.db-card {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 22px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    &:last-child{
        margin-right: 0px;
    }
    .icon-wrap{
        padding: 19px;
        height: 69px;
        width: 69px;
        border-radius: 50%;
        margin-right: 27px;
    }
    .db-card-content{
        .sub-title{
            font-size: 12px;
            font-weight: 400;
        }
        .card-title {
            color: var(--text-black);
            font-size: 30px;
            font-weight: 600;
            &.statement {
                color: var(--orange);
                font-size: 1.5em;
            }
        }
        .percentage{
            font-size: 12px;
            font-weight: 500;
        }
        .orange{
            color: var(--orange);
        }
        .green{
            color: green;
        }
        .total-count{
            color: var(--gray);
            font-size: 16px;
            font-weight: 500;
        }
        .info{
            font-size: 12px;
            color: var(--gray);
        }
    }
    @media only screen and (max-width: 1024px) {
        margin-bottom: 10px;
    }
}
