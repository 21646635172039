@import "../../../../../index.scss";

.text-dark {
    color: #0a0a0a;
}

.view-stays {
    color: var(--primary);
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    margin-top: 16px;
    transition: all 100ms ease;
    &:hover {
        text-decoration: underline;
    }
    img {
        display: none;
    }

    @media (max-width: 1024px) {
        text-decoration: none;
        display: flex;
        align-items: center;
        height: fit-content;
        &:active {
            text-decoration: underline;
        }
        img {
            display: inline !important;
            height: fit-content;
            margin-left: 15px;
        }
    }
    @media (max-width: 425px) {
        font-size: 14px;
        margin-top: 10%;
    }
}

.odd-carousel {
    .category {
        font-size: 22px;
        text-transform: capitalize;
        @media (max-width: 768px) {
            font-size: 20px;
        }
        @media (max-width: 375px) {
            font-size: initial;
        }
    }
    .deal-offer,
    .deal-text {
        font-size: 26px;
        @media (max-width: 940px) {
            font-size: initial;
        }

    }

    .offers-inner-wrapper {
        cursor: pointer;
        position: relative;
        border-radius: 30px;
        @media (max-width: 1024px) {
            margin: 0 16%;
        }
        @media (max-width: 540px) {
            margin: 0 20px;
        }
        @media (max-width: 425px) {
            margin: 0;
            margin-left: 20px;
        }
    }

  .deal-content {
    position: absolute;
    top: 20px;
    padding: 30px 30px 40px;
    background-color: rgba(255, 255, 255, 0.938);
    border-radius: 30px 0 30px;
    width: 65%;

    @media (max-width: 930px) {
      width: 87%;
    }
    @media (max-width: 540px) {
      padding: 20px 20px 40px;
      width: 95%;
      top: 20px;
      margin: 5px;
    }

        .desc-green {
            color: var(--primary-green);
            margin-top: 5px;
            @media (max-width: 425px) {
                font-size: smaller;
            }
        }
    }

    .deal-img {
        height: 380px;
        border-radius: 30px;
        img {
            width: 1024px;
            height: inherit;
            object-fit: cover;
            border-radius: 30px;
        }
    }

    .slick-slide {
        opacity: 0.4;

        @media (max-width: 1920px) {
            transform: translate3d(-8%, 0, 0);
        }
        @media (max-width: 1600px) {
            transform: translate3d(0, 0, 0);
        }
        @media (max-width: 1440px) {
            transform: translate3d(4.5%, 0, 0);
        }
        @media (max-width: 1024px) {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    .slick-track {
        height: 380px !important;
        overflow: hidden;
    }

    .slick-slide.slick-active.slick-center.slick-current,
    .slick-slide.slick-active.slick-current {
        opacity: 1;
        @media (max-width: 425px) {
            width: 80vw !important;
        }
    }

    // Position card on scroll
    @for $i from 0 through 100 {
        $step: 1;
        .slick-slide[data-index="#{$i - $step}"] {
            padding-right: 25px;
            @media (max-width: 425px) {
                padding-right: 0;
            }
        }
    }

    .slick-arrow.slick-prev, 
    .slick-arrow.slick-next {
        top: 35%;
        &::before {
            content: "";
        }
    }      
}

.single-odd {
    .slick-slide.slick-active.slick-center.slick-current {
        margin: 0 21.5%;
        @media (min-width: 2560px) {
            margin: 0 35%;
        }
        @media (max-width: 1600px) {
            margin: 0 25%;
        }
        @media (max-width: 1440px) {
            margin: 0px 14%;
            margin-right: 13.5%;
        }
        
    }
    .slick-slide.slick-active.slick-current {
        @media (max-width: 375px) {
            width: 95vw !important;
        }
    }

    .slick-slide.slick-active.slick-center.slick-current,
    .slick-slide.slick-active.slick-current {
        opacity: 1;
        @media (max-width: 425px) {
            width: 95vw !important;
        }
    }
}

.offer-code-wrapper {
    padding: 10px 12px;
    border: 2px dashed var(--text-gray);
    background-color: #fff !important;
    opacity: 1;
    width: fit-content;
    margin-top: 16px;
    display: flex;

    .muted-text {
        color: #dbdbdb;
        margin-right: 10px;
    }

    .offer-code {
        font-size: 18px;
        font-weight: 600;
        color: var(--blue);
        margin-right: 10px;
    }

    .copy-button {
        padding: 6px;
        background-color: var(--primary);
        border-radius: 50%;
        display: flex;
        width: fit-content;
    }
}

.deal-content {
    @include directionSwitch(left, right, 20px);
    @media (max-width: 540px) {
        @include directionSwitch(left, right, 0px);
    }
}