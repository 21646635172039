.attractions-card {
    width: 285px;
    position: relative;

    &:hover {
        .attraction-title-holder {
            span {
                visibility: hidden;
            }
        }

        .hover-card {
            height: 140px !important;
        }
    }

    @media (min-width:990px) and (max-width:1550px) {
        max-width: 215px;
    }

    @media (max-width:990px) {
        max-width: 215px;
    }

    @media (max-width: 768px) {
        width: 97%;
    }

}

.section.home-attractions {
    font-size: 1.3em;
    max-width: 85%;
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto;
    border-radius: 50px;
    background-color: var(--muted-primary);
    padding: 0px;

    @media (max-width:768px) {
        max-width: 100%;

    }

    @media (max-width: 425px) {
        .rec .rec-carousel {
            margin-left: 20px;
        }
    }

    .all-title {
        padding-top: 30px;
        padding-bottom: 30px;

        @media (max-width:660px) {

            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    .rec-slider {
        @media (max-width:768px) {
            margin: 0px;

        }
    }


    .rec-pagination {
        padding-bottom: 50px;

        @media (max-width:660px) {
            padding-bottom: 70px;
        }
    }

    .rec-swipable {
        padding-bottom: 20px;
    }

    .react-multi-carousel-track {
        margin-bottom: 55px;
    }

    .react-multi-carousel-item {
        display: flex;
        justify-content: center;
    }

    .rec-dot {
        width: 14px;
        height: 6px;
        border-radius: 3px;
        background-color: var(--primary);
        opacity: 0.4;
        border: none;
        box-shadow: none;
    }

    .rec-dot_active {
        background: var(--primary);
        width: 50px;
        height: 6px;
        border-radius: 3px;
        border: none;
        opacity: 1;
        border: none;
        box-shadow: none;
    }

    .rec-arrow {
        display: none;
    }

    .attraction-title-holder {
        border-radius: 0px 0px 20px 20px;
        /* position: absolute; */
        bottom: 9px;
        /* margin-left: 10px; */
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
        width: 100%;
        height: 13%;
        color: white;

        span {
            margin-left: 20px;
        }
    }

    .hover-card {
        text-align: center;
        transition: 0.7s;
        height: 0px;
        background: #000000b5;
        width: 100%;
        color: white;

        .subtitle {
            font-size: 13px;
            line-height: 20px;
            text-align: justify;
            padding: 10px 25px;
            color: white;
            word-break: break-all;
        }

        .title {
            color: white;
            font-size: 20px;
            margin-top: 10px;
            font-weight: 500;
            text-align: left;
            padding-left: 25px;
            padding-top: 10px;
        }
    }

    .attractionsTitle {
        width: 100%;
        text-align: center;
        float: left;
        margin-bottom: 20px;
        font-size: 28px;
        font-family: Rubik;
        font-size: 26px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        /* text-align: left; */
        color: var(--text-black);
        margin-left: 2%;

        @media (max-width:660px) {
            font-size: 24px;
            width: 100%;
            margin: 0 20px 30px;
        }
    }
}

.module {
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/skyscrapers.jpg);
    width: 95%;
    height: 385px;
    position: relative;
    overflow: hidden;
    margin: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;

    @media (max-width: 1024px) {
        width: 75%;
        height: 250px;
    }

    @media (max-width: 880px) {
        width: 95%;
    }

    @media (max-width:768px) {

        margin: 2px;
    }

    &:hover {
        h1 {
            visibility: hidden;
        }

        .hover-card {
            height: 200px !important;
        }
    }

    .hover-card {
        height: auto;
        bottom: 0px !important;

        .subtitle {
            padding-bottom: 35px !important;
        }
    }
}

.module>header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    overflow: hidden;
    padding-bottom: 15px;
    padding-left: 25px;
    font-size: 18px;
}

.module>header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .7));
}

.module>header>h1 {
    margin: 0;
    color: white;
    position: relative;
    z-index: 1;
}