.legend-holder {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-right: 35px;
  &:last-child{

  margin-right: 0px;
  }
  .legend {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    &-vacated {
      background-color: #a42420;
    }
    &-occupied {
      background-color: #70b730;
    }
    &-upcoming {
        background-color: #f39b3e;
      }
  }
}
