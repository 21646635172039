.map-loader {
    height: 300px;
    background-color: #f2f2f2;
    border-radius: 20px;
    margin-top: 60px;
    will-change: transform;
    animation: placeHolderShimmer 1.5s linear infinite forwards;
    -webkit-backface-visibility: hidden;
    background: #e6e6e6;
    background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
    background-size: 800px 104px;
    position: relative;

    @keyframes placeHolderShimmer {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            background-position: -768px 0;
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            background-position: 768px 0;
        }
    }
}
