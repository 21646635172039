@import "../../../../index.scss";
.wishlist-wrap {
    @media (max-width:767px) {
        border: 2px solid var(--border-light-gray);

    }
}

.wishlist {
    &-name {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        @include valueSwitch (text-align,left,right);
        color: #060709;
        @include directionSwitch(padding-right,padding-left, 20px);
        width: calc(100% - 150px);
    }

    &-columns {
        padding: 20px;
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin: 0 auto !important;
        border-bottom: 1px solid #eee;

        @media (max-width:767px) {
            display: block;

        }

        .filler-card {
            height: auto;
        }

        .featured-card-amenities {
            margin-top: 7px;
            background-color: transparent;
        }

        .book-btn {
            border-radius: 30px;

            @media screen and (max-width:768px) {
                width: 100px;
                height: 40px;
            }
        }

    }


    &-image {
        border-radius: 24px;
        max-width: 260px;
        height: 215px;
        object-fit: cover;

        @media (max-width:1300px) and (min-width:1024px) {
            max-width: 200px;
            object-fit: cover;
        }

        @media (max-width:767px) {
            max-width: 100%;
            height: auto;
        }
    }

    &-amenties {
        display: flex;

        align-items: center;
        justify-content: flex-start;
        padding: 0 10px 0 0;

        @media (max-width:1200px) and (min-width:1024px) {
            flex-wrap: wrap;
        }

        @media (max-width:600px) {
            flex-wrap: wrap;
        }

        img {
            height: 19px;
        }

        .amenity-name {
            font-size: 13px;
        }
    }

    &-contents {
        width: 60%;
        margin-top: 15px;
        position: relative;

        @media (max-width:767px) {
            width: 100%;
            padding-top: 20px;
        }
    }

    &-wrapper {
        margin-top: 20px
    }

    &-price {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #53575e;
        @include directionSwitch (margin-left,margin-right, 20px);
    }
}

.wishlist-wrapper-di {
    margin-top: 10px
}

.wishlist-image-container {
    position: relative;
}

.price-night {
    font-weight: 400;
    font-size: 17px;
}

.wishlist-booking-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: absolute;
    @include directionSwitch(right,left, 0);
    top: 0;
    flex-direction: column;

    .book-btn {
        @include valueSwitch (margin-left,1em, 0 !important);
        @media (max-width:767px) {
            font-size: 12px;
        }
    }

    .rating-value {
        margin-right: 10px;
    }

    .rating-wrap {
        display:flex;
        align-items: center;
        padding: 11px 0;
    }

    @media (max-width:767px) {
        top: 21px
    }
}

.wishlist-columns:last-child {
    margin-bottom: 5px !important;
    border-bottom: none;
}

.hide-mob {
    @media (max-width:1100px) {
        display: none;
    }
}

.show-mob {
    @media (min-width:1100px) {
        display: none;
    }
}

.unavailable-unit {
    font-size: 16px;
    padding-left: 7px;

    @media (max-width:1100px) {
        padding-left: 0;
        display: block;
    }
}

.grayscale {
    filter: grayscale(1);
    pointer-events: none;
    background-color: var(--text-gray)
}

.disabled-card {
    pointer-events: none;
    color: gray !important;

    .result-card-std-title {
        color: gray !important;

    }
}

.placeholder-image-style {
    background-color: #e4f7f1;
    width: 260px;
    height: 215px;
    border-radius: 24px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .wishlist-image {
        height: 125px;
    }

    @media (max-width: 1300px) and (min-width: 1024px) {
        width: 200px;
    }

    @media screen and (max-width:767px) {
        width: 100%;
    }
}

.orange {
    color: var(--orange)
}