.table-card {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    margin-top: 24px;
    .table-card-header {
        display: flex;
        justify-content: space-between;
    }
    h4 {
        border-bottom: 1px solid var(--light-body-color);
        padding: 22px 24px;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .view-all-link {
        color: var(--primary);
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        transition: all 100ms ease;
        &:hover{
            text-decoration: underline;
        }
    }
    .no-data {
        text-align: center;
        margin-bottom: 10px;
    }
    table {
        color: #53575e;
        .table-header {
            background-color: #f3f3f3;
            font-size: 14px;
            font-weight: 500;
        }
        th {
            padding: 17px 24px;
        }
        tbody {
            font-size: 13px;
            font-weight: normal;
            td {
                padding: 16px 24px;
            }
        }
        .tag {
            color: white;
            width: 75px;
            padding: 4px 5px;
        }
        .yellow {
            background-color: #fc9403;
        }
        .green {
            background-color: #1aaa55;
        }
        .red {
            background-color: #ce3737;
        }
        .dark-green{
            background-color: #04441f;
        }
        .paleYellow{
            background-color: #dae907;
        }
    }
}
.table-wrap{
    overflow-x: auto;
    table{
        margin-bottom: 20px !important;
       
    }
}