.occupancy-card {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    margin-top: 24px;
    margin-right: 20px;
    h4 {
        border-bottom: 1px solid var(--light-body-color);
        padding: 22px 24px;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .chart-details {
        color: #53575e;
        font-size: 16px;
        padding: 18px 24px;
    }
    .chart-container {
        display: flex;
        justify-content: center;
        color: #53575e;
        font-size: 12px;
    }
    .legend {
        color: #53575e;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 2rem;
        .square {
            display: flex;
            align-items: center;
            &:before {
                content: "";
                display: inline-block;
                width: 15px;
                height: 15px;
                margin-right: 5px;
            }
        }
        .short{
            &:before{
                background: #57B95A;
            }
        }
        .long{
            &:before{
                background: #42B9E6;
            }
        }
    }
}
