@import "../../../../../../index.scss";

.search-bar .dropdown {
    &-title {
        position: absolute;
        top: -10px;
        left: 15px;
        /* border-radius: 14px; */
        background: white;
        font-size: 0.9em;
        color: var(--text-gray);
        padding-left: 4px;
        padding-right: 4px;
        z-index: 1;
    }

    &-subtitle {
        color: var(--text-gray);
        display: flex;
        align-items: center;
        word-spacing: 3px;
        position: relative;
        width: 100%;

        @media (max-width: 450px) {
            font-size: 14px;
        }

        img {
            filter: grayscale(1);
        }
    }

    &-button {
        position: relative;
        height: 56px;
        display: flex;
        justify-content: space-between;
        padding-left: 16px;
        padding-right: 15px;
        transition: 0.1s;
        border-width: 2px;
    }

    &-button:first-of-type {
        width: 240px;
        @media screen and (max-width:1080px) {
            width: 19vw;
            font-size: 14px;
        }
    }

    .guest-dropdown {
        width: 275px;
        box-shadow: #48484833 0px 15px 20px;
    }

}

.search-dropdown {
    .item {
        padding-top: 10px;
        transition: 0.8s;
        padding-bottom: 10px;
        cursor: pointer;

        &:hover {
            background-color: var(--light-bg-color);
        }
    }

    &-btn:focus {
        border-color: #57c3ec;
    }
}

.n-calendar {
    width: 250px;
    box-shadow: #48484833 0px 15px 20px;
    margin-top: 10px;
    padding: 24px;
}


.selected-body {
    color: black !important;
    font-weight: 500;
}

.guest-dd {
    height: 80px;
    margin: auto;
    display: flex;
    align-items: center;
}

.search-autocomplete {
    outline: none;
    border: none;
}

.popular-locations {
    font-size: 16px;
    font-weight: 500;
    color: #272626;
    margin-bottom: 10px;
    text-align: left;
}

.checkout-menu-mob {
    right: -2px !important;
    left: auto !important;
    @media (max-width: 1280px) {
        @include valueSwitch(right, 0px !important, -125px !important);
    }
    @media (max-width: 1024px) {
        right: -2px !important;
    }

}

.checkin-menu-mob{
    right: -2px !important;
    left: auto !important;
    @media (max-width: 1020px) {
        @include valueSwitch(right, -120px !important, 0px !important);
    }
    @media (max-width: 1024px) {
        right: -2px !important;
    }
}

.search-bar {
    .dropdown-button:focus,
    .drodown-button.is-focused {
        border-color: #dbdbdb;
    }

    .button:focus:not(:active),
    .button.is-focused:not(:active) {
        box-shadow: none;
    }
}

.focused-btn {
    border-color: #57c3ec !important;
    color: #363636;
    border-width: 2px !important;
    box-shadow: none;
}

.focused-text {
    color: #57c3ec !important;
}