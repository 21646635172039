.even-carousel {
    background-color: var(--light-bg-color);
    @media (max-width: 768px) {
        padding-left: 0 !important;
    }

    .offers-inner-wrapper {
        display: flex;
        border-radius: 30px;
        box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.16);
        background-color: var(--white);
        margin: 80px 25px;
        width: 1024px;
        @media (max-width: 2560px) {
            transform: translate3d(-31%, 0, 0);
        }
        @media (max-width: 1920px) {
            transform: translate3d(-8%, 0, 0);
        }
        @media (max-width: 1600px) {
            transform: translate3d(0, 0, 0);
        }

        @media (max-width: 1024px) {
            width: 67vw;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            min-height: 680px;
        }
        @media (max-width: 425px) {
            width: 83vw;
            margin-left: 20px;
            min-height: 490px;
        }

        .deal-img {
            padding: 21px 19px;
            border-radius: 2rem 0 2rem 2rem;
            img {
                border-radius: 2rem 0 2rem 2rem;
                width: 529px;
                min-height: 357px;
                max-height: 357px;
                height: 100%;
                object-fit: cover;
                @media (max-width: 1024px) {
                    width: 100%;
                }
                @media (max-width: 425px) {
                    min-height: 1px;
                    height: 220px;
                }
            }
            @media (max-width: 768px) {
                padding: 20px;
            }
        }
        .deal-content {
            flex: 1;
            padding: 58px 19px 20px;
            padding-right: 50px;
            @media (max-width: 1024px) {
                padding:0 20px;
            }
        }
    }

    .slick-slide {
        opacity: 0.4;
    }
    .slick-slide.slick-active.slick-center.slick-current,
    .slick-slide.slick-active.slick-current {
        opacity: 1;
    }

    .slick-slide.slick-active.slick-current {
        @media (max-width: 425px) {
            width: fit-content !important;
        }
    }

    .slick-dots {
        margin-top: 0 !important;
        padding-bottom: 80px !important;
        @media (max-width: 768px) {
            padding: 30px !important;
        }
    }

    .slick-arrow.slick-prev, 
    .slick-arrow.slick-next {
        top: 40%;
        &::before {
            content: "";
        }
    }

    .deal-text,
    .deal-offer {
        font-size: 28px !important;
        font-weight: 500;
        @media (max-width: 425px) {
            font-size: 18px !important;
        }
    }

    .deal-text {
        color: var(--gray);
    }

    .category {
        font-size: 20px !important;
        color: var(--gray);
        text-transform: capitalize;
        @media (max-width: 425px) {
            font-size: 14px !important;
        }
    }

    .desc {
        font-size: 16px;
        color: var(--gray);
        margin-top: 20px;
        &-green {
            color: var(--primary-green);
            margin-top: 5px;
            @media (max-width: 425px) {
                font-size: smaller;
            }
        }
    }

    .desc-button {
        text-transform: uppercase;
        height: 60px;
        border-radius: 30px;
        border: none;
        background-color: var(--primary);
        width: 180px;
        font-size: 18px;
        font-weight: 500;
        color: var(--white);
        margin-top: 70px;

        cursor: pointer;
        &:hover {
            box-shadow: 0 4px 12px 0 rgba(65, 185, 230, 0.32);
        }

    @media (max-width: 425px) {
      width: 100%;
      font-size: 14px;
      height: 50px;
    }
    @media (max-width: 1024px) {
      margin-top: 25px;
      bottom: 25px;
      max-width: 200px;
      margin-bottom: 25px;
    }
  }

    .even-carousel-single-data {
        margin-left: 21%;
        margin-bottom: 40px;
        margin-top: 40px;
        @media (min-width: 2560px) {
            margin-left: 29%;
        }
        @media (max-width: 1440px) {
            margin-left: 17%;
            margin-right: auto;
            width: 1020px;
            .deal-img img {
                width: 450px;
            }
        }
        @media (max-width: 1200px) {
            width: 865px;
            .deal-img img {
                width: 400px;
            }
        }
        @media (max-width: 1042px) {
            flex-direction: column;
            width: 67vw;

            .deal-img {
                img {
                    border-radius: 2rem 0 2rem 2rem;
                    height: 227px;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .deal-content {
                padding: 10px 21px 20px;
            }
            .desc-button {
                width: 100%;
            }
        }
        @media (max-width: 540px) {
            width: fit-content;
            margin-left: 20px;
            margin-right: 20px;
        }
    }

}

.w-100vw {
    width: 100vw !important;
}