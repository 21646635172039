@import "../../../../index.scss";

.center-xy {
    display: flex;
    justify-content: center;
    align-items: center;
}

.corner-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.coupon-buttons {
    cursor: pointer;
    user-select: none;
    img {
        height: fit-content;
    }
    &:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
    }
}
.disable-Coupon {
    cursor: not-allowed !important;
    pointer-events: none;
    span {
        background-color: var(--text-gray) !important;
        cursor: not-allowed !important;
    }
}

.play-button {
    @extend .center-xy;
    @extend .coupon-buttons;
    width: 42px;
    min-width: 42px;
    height: 42px;
    border-radius: 30px;
    border: 2px solid var(--border-gray);
    background-color: var(--white);

    @media (max-width: 425px) {
        width: 28px;
        min-width: 28px;
        height: 28px;
        order: 2;
    }


    img {
        transition: all 400ms ease;
        height: 12px;
    }
}
 
.copy-button {
    @extend .center-xy;
    @extend .coupon-buttons;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: var(--primary);
    @include directionSwitch(margin-right, margin-left, 10px);
    @media (max-width: 425px) {
        width: 28px;
        min-width: 28px;
        height: 28px;
        order: 2;
    }

    i {
        color: var(--white);
    }
}

.coupons {
    &-wrapper {
        border-radius: 2rem;
        border: 2px solid var(--border-light-gray);
        overflow: hidden;
        @media (max-width: 768px) {
            border: none;
        }

        .message  {
            cursor: unset;
            margin-bottom: 0 !important;
        }

        .is-collapsible.message-body .message-body-content {
            padding: 1.25em 1em;
            padding-top: 0;
        }

        .message a:not(.button):not(.tag):not(.dropdown-item) {
            text-decoration: none;
        }

        .message {
            background-color: var(--white);

            &-header,
            &-body-content {
                background-color: var(--white);
                padding-right: 0 !important;
                padding-left: 0 !important; 
            }

            &-header {
                justify-content: space-between;
                align-items: flex-start;
                @media (max-width: 900px) {
                    flex-wrap: wrap;
                }
            }

            &-body-content {
                margin-top: 6px;
                animation: expand 300ms;
                word-break: break-all;

                ul {
                    list-style: inside;
                    margin-left: 16px;
                }

                li {
                    font-size: 18px;
                    font-weight: 300;
                    color: var(--text-black);
                    @media (max-width: 425px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &-section {
        border-bottom: 2px solid var(--border-light-gray);
        @media (max-width: 768px) {
            border: none;
        }

        .title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0 !important;
            color: var(--text-black);
        }
    }

    &-header-grid {
        margin: 38px 42px;
        @media (max-width: 1024px) {
            margin: 38px 20px;
        }
        @media (max-width: 768px) {
            margin: 20px 5px 10px;
        }
    }

    &-grid {
        margin: 0 42px;
        @media (max-width: 1024px) {
            margin: 0 20px;
        }
        @media (max-width: 768px) {
            margin: 0 0;
        }
    }
}

.message-body {
    padding: 0 !important;
}

.edit-btn {
    border-radius: 1.875rem;
    background-color: var(--white);
    border: 2px solid var(--primary);
    padding: 13px 20px;
    color: var(--primary);
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 500;
    @media (max-width: 768px) {
        padding: 10px 15px;
    }

    i {
        @include directionSwitch(margin-right, margin-left, 0.5rem);
    }
}

@keyframes expand {
    0% {
        margin-top: -72px;
    }
    100% {
        margin-top: 6px;
    }
}

@keyframes collapse {
    0% {
        margin-top: 6px;
    }
    100% {
        margin-top: -22px;
        height: 0;
    }
}

.rotate-90 {
    .play-button-img {
        transition: all 400ms ease;
        transform: rotate(90deg);
        width: inherit;
    }
}

.collapse-message-body .message-body-content {
    margin-top: -22px;
    animation: collapse 300ms;
    visibility: hidden;
    height: 0;
}

.no-border-bottom {
    border-bottom: none !important;
    @media (max-width: 768px) {
        border-bottom: solid 2px var(--border-light-gray) !important;
    }
}

.accordian-container {
    border-bottom: 2px solid var(--border-light-gray);
    padding: 25px 0;
    @media (max-width: 768px) {
        border: 2px solid var(--border-light-gray);
        padding: 18px;
        border-radius: 20px;
        margin-bottom: 16px;
    }
    

    .left-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-width: 520px;
        min-width: 520px;
        word-break: break-all;
        @media (max-width: 768px) {
            max-width: auto;
            min-width: auto;
        }
        @media (max-width: 425px) {
            justify-content: space-between;
            align-items: flex-start;
            > span {
                margin-bottom: 8px;
            }
        }

    }

    .name {
        font-size: 22px;
        font-weight: bold;
        color: var(--blue);
        text-transform: uppercase;
        @include directionSwitch(margin-right, margin-left, 10px);
        width: inherit;
        word-break: break-word;

        @media (max-width: 425px) {
            font-size: 20px;
        }
        @media (max-width: 425px) {
            order: 1;
        }
    }
    
    .discount {
        font-size: 16px;
        font-weight: 500;
        color: var(--orange);
        @include directionSwitch(margin-right, margin-left, 10px);
        @media (max-width: 425px) {
            order: 3;
            width: 100%;
        }
    }

    .description {
        font-size: 17px;
        font-weight: normal;
        color: var(--text-black);
        margin-top: 10px;
        width: 100%;
        @media (max-width: 425px) {
            order: 4;
            font-size: 16px;
        }
    }

    .accordian-text {
        margin-right: 10px;
        @media (max-width: 425px) {
            order: 1;
            width: 85%;
        }
        @media (max-width: 325px) {
            width: 80%;
        }
    }

    .validity {
        @include directionSwitch(margin-right, margin-left, 20px);
        font-size: 16px;
        font-weight: 500;
        color: var(--gray);
        min-width: 120px;

        @media (max-width: 425px) {
            font-size: 14px;
            font-weight: 200;
            order: 3;
            margin-top: 18px;
        }

        .date {
            font-weight: 700;
            color: var(--text-black);
            display: block;
            margin-top: 10px;
        }
    }
}

.list-heading {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
    color: var(--text-black);
    @media (max-width: 425px) {
        font-size: 14px;
    }
}

.list-item {
    display: flex;
    align-items: center;
    margin-left: 16px;
    @media (max-width: 425px) {
        align-items: baseline;
    }
}

.bullet {
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    width: 6px;

    &-wrapper {
        display: flex;
        @include directionSwitch(margin-right, margin-left, 10px);
        align-self: flex-start;
        margin-top: 8px;
    }
}

.no-result-coupon {
    display: flex;
    padding: 50px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 768px) {
        border: 2px solid var(--border-light-gray);
    }

    h3 {
        font-size: 22px;
        font-weight: 600;
        color: var(--gray);
        margin-top: 32px;
    }

    p {
        font-size: 16px;
        color: var(--text-gray);
        margin-top: 32px;
    }

    a {
        margin-top: 48px;
        padding: 10px 38px;
        border-radius: 30px;
        color: #fff;
        border: none;
        font-size: 18px;
        font-weight: 500;
        background-color: var(--primary);
        cursor: pointer;
        
        &:hover {
            color: #fff;
            box-shadow: 0 4px 12px 0 rgba(65, 185, 230, 0.32) !important;
        }
    }
}

.hide-acc {
    display: none;
}

.light-blue-bg {
    background-color: #f1fafe;
}

.stay-details-coupon-btn {
    background-color: var(--primary);
    padding: 12px 20px;
    border-radius: 30px;
    cursor: pointer;
    margin: 6px 0;
    &:hover {
        box-shadow: 0 4px 12px 0 rgba(65, 185, 230, 0.32) !important;
    }

    &-wrapper {
        display: flex;
        width: 100%;
        @media (max-width: 425px) {
            order: 3;
        }
    }
    @media (max-width: 425px) {
        padding: 10px 20px;
        font-size: 90%;
    }
}


.selected-coupon-highlight {
  background-color: var(--light-bg-color);
  .message,
  .message-header,
  .message-body-content {
    background-color: var(--light-bg-color);
  }
}

.height-0 {
    height: 0;
}

.height-auto {
    height: auto;
}
