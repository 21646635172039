.addguest {

    &-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &-title {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        text-align: left;
        color: #000;
    }

    &-title-container {
        display: flex;
        align-items: center;
    }

    &-subtitle {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.23px;
        text-align: left;
        color: var(--text-gray);
    }

    &-counter {
        height: 42px;
        width: 42px;
        /* border: solid; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        /* border-width: 1px; */
        /* border-color: gray; */
        margin-right: 15px;
        margin-left: 15px;
        align-items: center;
        border: solid 1px var(--border-gray);
        border-radius: 6px;
        box-shadow: 0 3px 6px 0 rgba(45, 31, 80, 0.03);

        @media screen and (max-width: 768) {

            margin-right: 30px;
            margin-left: 30px;
        }

        &:hover {
            background-color: var(--light-bg-color);
        }

        &-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &+div {
                display: flex;
                align-items: center;
            }
        }
    }

    &-value {
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.31px;
        text-align: left;
        color: #000;
    }

    .addguest-check-content {
        margin-top: 30px;

        span {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.27px;
            text-align: left;
            color: var(--gray);
        }

        input {
            width: 25px;
            height: 21px;
            margin-right: 15px;
        }
    }
}