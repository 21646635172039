@import '../../../../index.scss';

.locations-card {
    position: relative;
    width: 95%;
    border-radius: 30px;
    background-color: var(--muted-primary);
    margin-bottom: 60px;

    &:hover .card-button {
        visibility: visible;
        opacity: 1;

        >img {
            animation: arrow-in 0.3s ease;
        }
    }

    @media (max-width: 1024px) {
        width: 100%;
    }

    .card-button {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 193px;
        height: 60px;
        padding: 19px 25.2px 19px 25px;
        border-radius: 30px;
        border: none;
        background-color: var(--primary);
        font-size: 18px;
        font-weight: 500;
        color: var(--white);
        cursor: pointer;
        transition: all 500ms 250ms ease-in-out;

        >img {
            @include directionSwitch(margin-left, margin-right, 10px);
        }
    }
}

@keyframes arrow-in {
    0% {
        transform: translateX(-15%);
    }

    100% {
        transform: translateX(0);
    }
}

.locations-image {
    height: 283px;

    img {
        height: inherit;
        object-fit: cover;
        border-radius: 30px 30px 0 0;
        width: 100%;
    }
}

.locations-card-content {
    padding: 32px 59px 59px 42px;
}

.locations-card-content-title {
    font-size: 28px;
    font-weight: 500;
    color: var(--text-black);
    text-transform: uppercase;
    margin-bottom: 24px;
}

.locations-card-content-description {
    font-size: 15px;
    color: var(--gray);
    margin-bottom: 24px;
}

.location-tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
}

.location-tag {
    border-radius: 30px;
    border: solid 2px var(--text-gray);
    padding: 9px 16px 8px;
    margin-right: 12px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-gray);
}