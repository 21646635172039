.search {
    margin-bottom: 90px !important;

    .search-bar .dropdown-button:first-of-type {
        width: 180px;
    }

    [dir=rtl] .search-bar .button .icon:last-child:not(:first-child) {
        margin-left: -0.75em;
    }

    .search-bar .autocomplete-btn:first-of-type {
        input {
            font-size: 16px;
            font-weight: 500;
            color: var(--text-gray);
            height: 40px;
            color: black;
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media screen and (min-width:768px) and (max-width:1110px) {
                width: 85vw;
            }
            @media screen and (max-width:768px) {
                width: 80vw;
            }
            @media screen and (max-width:450px) {
                width: 75vw;
            }
        }
        width:275px;

        @media screen and (min-width:1500px) {
            width: 19vw !important;
            max-width: 330px;
        }
    }

    &-header {
        height: 90px !important;
        min-height: 0px !important;
    }

    &-container {
        width: 100%;
    }

    &-title {
        margin-top: 60px;
        margin-bottom: 60px !important;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.57;
        letter-spacing: normal;
        text-align: left;
        color: var(--text-black);

        @media screen and (max-width: 550px) {
            margin-top: 60px;
            margin-bottom: 45px !important;
        }
    }

    &-subtitle {
        margin-bottom: 25px;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.86;
        letter-spacing: normal;
        text-align: left;
        color: var(--gray);

        @media screen and (max-width: 550px) {
            text-align: center;
        }
    }

    &-card-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

.searchbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: -51px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    &-btn {
        background-color: var(--light-bg-color) !important;
        font-weight: 400;
        border: none;
        font-size: 16px;
        border-radius: 24px;
        font-size: 18px;
        font-weight: 500;
        width: 110px;
        border-radius: 24px !important;
        font-size: 18px !important;
        font-weight: 500;
        width: 110px;
    }

    &-icon {
        @extend .searchbar-btn;
        width: 45px;
    }

    .box {
        box-shadow: 0 13px 66px 0 rgba(0, 0, 0, 0.11);
        border-radius: 100px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: inherit;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        align-items: center;

        @media screen and (min-width:1500px) {
            width: 75vw;
            max-width: 1180px;
        }
    }
}

.hide-small {
    @media (min-width: 1110px) {
        display: none !important;
    }
}

.show-small {
    display: none !important;

    @media (min-width: 1110px) {
        display: inherit !important;
    }
}

.w-40 {
    width: 40%;
}

.autoc-item {
    font-size: 14px;
    font-weight: 500;

    @media screen and (max-width:768px) {
        font-size: 16px;
    }
}

.autoc-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: gray;
    text-align: left;

    @media screen and (max-width:768px) {
        font-size: 16px;
        font-weight: 400;
        color: gray;
        margin-left: 25px;
        overflow: hidden;
        width: 60vw;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.autoc-title {
    display: flex;
    align-items: center;
    text-align: left;
}

.hidden {
    display: none;
}

.search-property-main {
    display: flex;
    margin: auto;
    align-items: center;
    flex-direction: column;

    .search-container {
        max-width: 1110px;
        margin: auto;
    }
}

.search-property-main {
    .search-bar-content {
        width: 95%;

        @media screen and (max-width:1300px) {
            width: 100%
        }
    }
}

.checkIn{
    img{
        margin-right: 0.75rem;
    }
}